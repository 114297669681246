<template>
  <chip>
    <spinner class="loading-feedback__spinner" />
    <span class="loading-feedback__text">
      <slot />
    </span>
  </chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Spinner } from '@airgrid/components';
import Chip from '@/components/UI/Chips/Chip.vue';

export default defineComponent({
  name: 'LoadingNotificationChip',
  components: {
    Spinner,
    Chip,
  },
});
</script>

<style lang="scss" scoped>
// @define loading-feedback

@import '@airgrid/components/styles/shared/typography';

.loading-feedback__text {
  @extend %generic-text-styles;

  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--color-blacks-white);
}
.loading-feedback__spinner {
  width: 14px;
}
</style>
