import { BackendError, ClientError } from '@/store/errors';

export enum PublishersErrorCodes {
  PUBLISHER_ALREADY_EXIST = 'PUBLISHER_ALREADY_EXIST',
  PUBLISHER_ONBOARDING_IS_NOT_ALLOWED = 'PUBLISHER_ONBOARDING_IS_NOT_ALLOWED',
}

export class PublisherAlreadyExistError extends ClientError {
  constructor(err?: BackendError) {
    super(
      PublishersErrorCodes.PUBLISHER_ALREADY_EXIST,
      err ? err.response.data.message : 'Publisher already exists.'
    );
  }
}

export class PublisherOnboardingIsNotAllowedError extends ClientError {
  constructor() {
    super(
      PublishersErrorCodes.PUBLISHER_ONBOARDING_IS_NOT_ALLOWED,
      'Publisher onboarding is allowed for the admin users or accounts with appropriate permissions.'
    );
  }
}
