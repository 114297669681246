<template>
  <no-connection-icon class="user-offline-banner__icon" />
  <span>
    <strong> You are offline. </strong>
    Check your connection and
    <span class="action" @click="onClick">try again</span>.
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NoConnectionIcon from '@/assets/icons/no-connection.svg';

export default defineComponent({
  name: 'UserOfflineDateBanner',
  components: {
    NoConnectionIcon,
  },
  setup() {
    const onClick = () => {
      location.reload();
    };
    return {
      onClick,
    };
  },
});
</script>
