<template>
  <success-green-icon />
  <span>
    <strong>Application is up to date.</strong>
    We hope you enjoy the new features!
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SuccessGreenIcon from '@/assets/icons/success-green.svg';

export default defineComponent({
  name: 'ApplicationUpToDateBanner',
  components: {
    SuccessGreenIcon,
  },
});
</script>
