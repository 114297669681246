import { AnalyticsErrorCodes } from './errors';
import { BackendError, isBackendError } from '@/store/errors';

export const isReportGenerationError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.FAILED_CREATE_REPORT
  );
};

export const isMissingParametersError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.MISSING_PARAMETERS
  );
};

export const isAccountNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.NO_ACCOUNT_FOUND
  );
};

export const isNoRejectionReasonForDealIdError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      AnalyticsErrorCodes.NO_REJECTION_REASON_FOR_DEAL_ID
  );
};

export const isFailedToDecodeHashError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.FAILED_TO_DECODE_HASH
  );
};

export const isInvalidReportTypeError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.INVALID_REPORT_TYPE_ERROR
  );
};

const isInvalidDataTypeError = (error: unknown): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === AnalyticsErrorCodes.INVALID_DATA_TYPE_ERROR
  );
};

export const analyticsErrorGuard = Object.freeze({
  isReportGenerationError,
  isMissingParametersError,
  isAccountNotFoundError,
  isNoRejectionReasonForDealIdError,
  isFailedToDecodeHashError,
  isInvalidReportTypeError,
  isInvalidDataTypeError,
});
