<script lang="ts">
export enum SearchModalResultsGroupEvents {
  CLOSE = 'close',
  UPDATE_HOVERED_ITEM = 'UPDATE_HOVERED_ITEM',
}
</script>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import ArrowRight from '@/assets/icons/arrow-right.svg';
import { makeMatchingCharactersBolder } from '@/utils';
import { RouteLocationRaw } from 'vue-router';

const emit = defineEmits(['close', 'UPDATE_HOVERED_ITEM']);

type BaseItem = {
  route: RouteLocationRaw;
  name: string;
  prefix?: string;
};

type SearchItem<T extends Record<string, any>> = BaseItem & T;

const props = defineProps<{
  title: string;
  items: SearchItem<Record<string, any>>[];
  searchString: string;
  hoveredItem?: number;
  hoveredGroup?: number;
  groupIndex: number;
}>();

const onClose = () => {
  emit('close');
};

const onMouseOver = (idx: number) => {
  emit('UPDATE_HOVERED_ITEM', {
    hoveredItem: idx,
    hoveredGroup: props.groupIndex,
  });
};

const onMouseLeave = () => {
  emit('UPDATE_HOVERED_ITEM', {
    hoveredItem: undefined,
    hoveredGroup: undefined,
  });
};

const isCurrentGroupHovered = computed(
  () => props.hoveredGroup === props.groupIndex
);

const scrollWrapperRef = ref();

watch(
  () => props.hoveredItem,
  (hoveredItem, previousHoveredItem) => {
    const maxVisibleItems = 5;
    const itemHeight = 34; // This equals item height of 32px + margin bottom 2px

    if (
      hoveredItem !== undefined &&
      previousHoveredItem !== undefined &&
      isCurrentGroupHovered.value
    ) {
      const delta = hoveredItem - previousHoveredItem; // Define direction delta
      const scrollAmount = delta * itemHeight; // Calculate the scroll amount based on the delta and item height

      // NOTE: We need start scrolling only when the next item is not visible
      if (
        (delta > 0 && hoveredItem >= maxVisibleItems) ||
        (delta < 0 && hoveredItem < props.items.length - maxVisibleItems)
      ) {
        (scrollWrapperRef.value as HTMLElement).scrollTop += scrollAmount;
      }
    }
  }
);
</script>

<template>
  <div class="search-modal-results-group" v-show="items.length > 0">
    <h3 class="search-modal-results-group-title">{{ title }}</h3>
    <div class="search-modal-results-group-wrapper" ref="scrollWrapperRef">
      <router-link
        v-for="(item, idx) in items"
        :key="idx"
        class="search-modal-results-group-item"
        :class="{
          'search-modal-results-group-item--hovered':
            idx === hoveredItem && isCurrentGroupHovered,
        }"
        @mouseover="onMouseOver(idx)"
        @mouseleave="onMouseLeave"
        @click="onClose"
        :to="item.route"
      >
        <div class="search-modal-results-group-item__left">
          <arrow-right class="search-modal-results-group-item-arrow-icon" />
          <span class="search-modal-results-group-item-name">
            <template v-if="item.prefix">
              {{ item.prefix }} &nbsp;>&nbsp;</template
            >
            <span
              v-html="makeMatchingCharactersBolder(searchString, item.name)"
            />
          </span>
        </div>

        <div class="search-modal-results-group-item__right">
          <slot name="item-right-section" :item="item"> </slot>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// @define search-modal-results-group

@import '@airgrid/components/styles/shared/typography';
@import '@/styles/animations';

.search-modal-results-group {
  &-wrapper {
    max-height: 176px;
    overflow-y: auto;
  }

  &-title {
    @extend %heading-3;
    margin-bottom: 8px;
  }

  :deep(.search-modal-results-group-item-label) {
    display: flex;
    height: 24px;
    box-sizing: border-box;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-block);
    background: var(--color-silver);
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 8px 8px 6px;
    max-height: 32px;
    @extend %generic-text-styles;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-blacks-grey);
    border-left: 2px solid var(--color-white);
    border-radius: var(--border-radius-element);
    background: var(--color-blacks-app-subback-grey);
    cursor: pointer;
    text-decoration: none;

    transition: border var(--transition-default);

    &:not(:last-of-type) {
      margin-bottom: 2px;
    }

    &.search-modal-results-group-item--hovered {
      border-left: 2px solid var(--color-primary-blue);
    }

    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 500px;
      transition: color var(--transition-default);
      :deep(strong) {
        transition: color var(--transition-default);
      }

      .search-modal-results-group-item.search-modal-results-group-item--hovered
        & {
        color: var(--color-primary-blue);

        :deep(strong) {
          // NOTE: !important is need to override inline style for the
          color: var(--color-primary-blue) !important;
        }
      }
    }

    &__left {
      align-items: center;
      display: flex;
    }
    &__right {
      align-items: center;
      display: flex;
      gap: 8px;
    }
  }

  &-item-arrow-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-right: 12px;
    background-color: var(--color-blacks-input-back-grey);
    border-radius: var(--border-radius-element);
    padding: 4px;
    color: var(--color-blacks-grey);

    .search-modal-results-group-item--hovered & {
      background-color: var(--color-back-blue);
      color: var(--color-primary-blue);
    }
  }
}
</style>
