import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { isProd } from '@/runtime-env';
import { router } from '@/router/router';

const isLocalHost = window.location.href.includes('localhost');

const IGNORED_ERROR_MESSAGES = [
  'Failed to fetch dynamically imported module',
  /^Unable to preload CSS for$/,
];

export const initSentry = (app: App<unknown>): void => {
  if (!isLocalHost) {
    Sentry.init({
      app,
      release: PACKAGE_INFO.version as string,
      environment: isProd ? 'production' : 'development',
      dsn: GLOBALCONFIG.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],
      logErrors: true,
      tracesSampleRate: isProd ? 0.5 : 1.0,
      tracePropagationTargets: [
        'localhost',
        '127.0.0.1',
        'https://api-dev.edkt.io',
        'https://api.edkt.io',
      ],
      replaysSessionSampleRate: isProd ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: IGNORED_ERROR_MESSAGES,
    });
  }
};

export const setUserToSentry = ({ email }: { email: string }): void => {
  Sentry.setUser({ email });
};

export const resetUserFromSentry = (): void => {
  Sentry.getCurrentScope().setUser(null);
};

export const addBreadcrumbToSentry = (
  breadcrumb: Sentry.Breadcrumb['data']
): void => {
  Sentry.addBreadcrumb({ data: breadcrumb });
};

// NOTE: adding custom context so that we can capture
// if the browser is online on any network errors.
// There is an issue if sentry updates this before sending the error,
// if they do it will always return `true`, hopefully they store the context
// at error time, and send that when the user comes back online.
let isOnline = navigator.onLine;
Sentry.setContext('network', { isOnline });
window.addEventListener('online', () => {
  isOnline = true;
  Sentry.setContext('network', { isOnline });
});
window.addEventListener('offline', () => {
  isOnline = false;
  Sentry.setContext('network', { isOnline });
});
