import { apiClient } from '@/store/api/client';

export async function resetPassword(
  oldPassword: string,
  newPassword: string
): Promise<void> {
  await apiClient.post<void>({
    url: `/users/reset-password`,
    data: {
      oldPassword,
      newPassword,
    },
  });
}

export async function updateEmail(email: string): Promise<void> {
  await apiClient.patch({ url: `/users/update`, data: { email } });
}
