export const capitalizeFirstLetter = (
  string: string,
  makeOtherLettersLowerCase = true
): string => {
  const lowerCaseStr = makeOtherLettersLowerCase
    ? string.toLowerCase()
    : string;
  const word = lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);

  return word || '';
};

export const formatCamelCase = (text: string): string =>
  capitalizeFirstLetter(
    text
      .replace(/(?:^|\.?)([A-Z])/g, (_x: string, y: string) => {
        return ' ' + y.toLowerCase();
      })
      .replace(/^_/, '')
  );

export const toLowerCase = (string: string): string => {
  return string.toLowerCase() || '';
};

export const roundNumber = (number: number, numDecimalPlaces = 2): number => {
  const delimiter = 10 ** numDecimalPlaces;
  return Math.floor(number * delimiter) / delimiter;
};

export const getSplittedWithCommasNumber = (input: string | number): string => {
  const splitted = String(input).split('.');
  splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return splitted.join('.');
};

/**
 * This method to format large numbers to be like:
 * 1,000 – 9,999
 • 10,000 – 99,999
 • 100k / 100.9k – 999k / 999.9k
 • 100m / 100.99m – 999m / 999.99m
 * @param number
 * @param numDecimalPlaces
 * @param config
 */
export const formatNumber = (
  number: number,
  numDecimalPlaces = 2,
  { millionSuffix = 'M', thousandSuffix = 'k', billionSuffix = 'B' } = {}
): string => {
  if (number < 1e-2 && number >= 1e-4) {
    return number.toString().slice(0, 6);
  } else if (number < 1e-4 && number >= 1e-6) {
    return number.toString().slice(0, 8);
  } else if (number < 1e-6 && number >= 1e-8) {
    return number.toString().slice(0, 10);
  }

  const integerNumber = parseInt(`${number}`, 10);
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const BILLION = 1000000000;
  let delimiter = 1;
  let suffix = '';

  if (integerNumber >= THOUSAND && integerNumber < MILLION) {
    delimiter = THOUSAND;
    suffix = thousandSuffix;
  } else if (integerNumber >= MILLION && integerNumber < BILLION) {
    delimiter = MILLION;
    suffix = millionSuffix;
  } else if (integerNumber >= BILLION) {
    delimiter = BILLION;
    suffix = billionSuffix;
  }
  const formatted = getSplittedWithCommasNumber(
    roundNumber(number / delimiter, numDecimalPlaces).toString()
  );
  return `${formatted}${suffix}`;
};

export const getFirstWord = (string: string): string => {
  if (string) {
    const firstWord = string.split(' ', 1).shift();
    return firstWord || '';
  }

  return '';
};

export const getCurrency = (currencyObject: {
  currency: string;
  value: number;
}): string => {
  if (!currencyObject.value) {
    return `${currencyObject.currency} -`;
  }
  return `${currencyObject.currency}${formatNumber(currencyObject.value)}`;
};

export const dashOnEmpty = (string: string): string => {
  if (string === '0') {
    return '-';
  }
  return string || '-';
};

export const formatCurrency = (
  amount: number,
  maximumFractionDigits = 0
): string => {
  const formattedAmount = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  });
  return formattedAmount.format(amount).replace('US', '');
};

export const truncate = (
  text: string,
  length: number,
  clamp = '...'
): string => {
  return text.length > length ? text.slice(0, length) + clamp : text;
};

export const formatPercent = (
  number: number,
  maximumFractionDigits = 0
): string => {
  const num = number.toString();
  return (
    parseFloat(num).toFixed(maximumFractionDigits).replace(/\.0+$/, '') + '%'
  );
};

export const nullToZero = (number: number | null | undefined): number => {
  return number ?? 0;
};

/**
 * Checks if the search query is included in the item's content.
 *
 * This function normalizes both the `itemContent` and `searchQuery` by converting
 * them to lowercase and removing all spaces. It then checks if the prepared
 * `searchQuery` is a substring of the prepared `itemContent`.
 *
 * @param {string} itemContent - The content of the item to search within.
 * @param {string} searchQuery - The search string to check for.
 * @returns {boolean} - Returns `true` if the normalized `searchQuery` is found
 *                      within the normalized `itemContent`; otherwise, `false`.
 */
export const doesSearchMatch = (
  itemContent: string,
  searchQuery: string
): boolean => {
  const prepareStringForSearch = (str: string) =>
    str.toLowerCase().replaceAll(' ', '');

  return prepareStringForSearch(itemContent).includes(
    prepareStringForSearch(searchQuery)
  );
};
