import { BackendError, ClientError } from '@/store/errors';

export enum AnalyticsErrorCodes {
  FAILED_CREATE_REPORT = 'FAILED_CREATE_REPORT',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  NO_ACCOUNT_FOUND = 'NO_ACCOUNT_FOUND',
  NO_REJECTION_REASON_FOR_DEAL_ID = 'NO_REJECTION_REASON_FOR_DEAL_ID',
  RATE_LIMIT_ERROR = 'RATE_LIMIT_ERROR',
  FAILED_TO_DECODE_HASH = 'FAILED_TO_DECODE_HASH',
  INVALID_REPORT_TYPE_ERROR = 'INVALID_REPORT_TYPE_ERROR',
  INVALID_DATA_TYPE_ERROR = 'INVALID_DATA_TYPE_ERROR',
}

export class ReportGenerationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.FAILED_CREATE_REPORT,
      err
        ? err.response.data.message
        : 'Failed to create a report for audience.'
    );
  }
}

export class MissingParametersError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.MISSING_PARAMETERS,
      err
        ? err.response.data.message
        : 'Cannot generate report. No sellerMemberId params present.'
    );
  }
}

export class AccountNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.NO_ACCOUNT_FOUND,
      err ? err.response.data.message : 'Cannot find account.'
    );
  }
}

export class NoRejectionReasonsForDealId extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.NO_REJECTION_REASON_FOR_DEAL_ID,
      err
        ? err.response.data.message
        : 'There is no data for rejection reasons yet.'
    );
  }
}

export class AudiencePlanRateLimitError extends ClientError {
  constructor() {
    super(
      AnalyticsErrorCodes.RATE_LIMIT_ERROR,
      'Too many requests! Please wait a few minutes before trying again.'
    );
  }
}

export class FailedToDecodeHash extends ClientError {
  constructor() {
    super(
      AnalyticsErrorCodes.FAILED_TO_DECODE_HASH,
      'Cannot get audience plan. Failed to decode hash.'
    );
  }
}

export class InvalidReportTypeError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.INVALID_REPORT_TYPE_ERROR,
      err ? err.response.data.message : 'Invalid report type.'
    );
  }
}

export class InvalidDataTypeError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AnalyticsErrorCodes.INVALID_DATA_TYPE_ERROR,
      err ? err.response.data.message : 'Invalid data type.'
    );
  }
}
