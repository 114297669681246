// TODO: this module can be removed once we share
// errors between FE and BE

import { ClientErrorCode } from './ClientError';
import { isAxiosError } from '../api/client';
import { hasResponseDataMessageAndCode } from '@/store/errors/ApplicationError';

export class BackendError extends Error {
  constructor(
    public readonly response: {
      status: number;
      data: {
        status: number;
        code: string;
        message: string;
      };
    }
  ) {
    super(response.data.message);
  }
}

export const isBackendError = (error: unknown): error is BackendError =>
  isAxiosError(error) &&
  hasResponseDataMessageAndCode(error) &&
  !!error.response?.status &&
  !!error.response?.data?.message &&
  !!error.response?.data?.code;

export const isBackendBuyerPlatformSeatHasDealsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.status === 400 &&
    error.response.data.code === ClientErrorCode.BUYER_PLATFORM_SEAT_HAS_DEALS
  );
};

export const isBackendDSPSeatNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.status === 400 &&
    error.response.data.code === ClientErrorCode.BUYER_PLATFORM_SEAT_NOT_FOUND
  );
};

export const isBackendSSPSeatNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.status === 400 &&
    error.response.data.code === ClientErrorCode.SSP_SEAT_NOT_FOUND
  );
};
