<template>
  <chip>
    <success-icon class="success-feedback__icon" />
    <span class="success-feedback__text">
      <slot />
    </span>
  </chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Chip from '@/components/UI/Chips/Chip.vue';
import SuccessIcon from '@/assets/icons/colored/success.svg';

export default defineComponent({
  name: 'SuccessFeedbackChip',
  components: {
    Chip,
    SuccessIcon,
  },
});
</script>

<style lang="scss" scoped>
// @define success-feedback

@import '@airgrid/components/styles/shared/typography';

.success-feedback__text {
  @extend %generic-text-styles;

  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--color-blacks-white);
}
.success-feedback__icon {
  width: 16px;
}
</style>
