import { ObjectDirective, DirectiveBinding } from 'vue';

interface HTMLElementClickOutside extends HTMLElement {
  clickOutsideMouseDownEvent?(event: MouseEvent): void;
  clickOutsideMouseUpEvent?(event: MouseEvent): void;
}

export const clickOutside: ObjectDirective<HTMLElement, HTMLElement> = {
  beforeMount(el: HTMLElementClickOutside, binding: DirectiveBinding): void {
    // NOTE: we do not need to handle outside click if mousedown occurred on the target element
    let shouldHandleClick = false;

    el.clickOutsideMouseDownEvent = function (event: MouseEvent) {
      shouldHandleClick = !el.contains(event.target as Node);
    };

    el.clickOutsideMouseUpEvent = function (event: MouseEvent) {
      if (shouldHandleClick) {
        binding.value(event, el);
      }
      shouldHandleClick = false;
    };

    document.body.addEventListener('mousedown', el.clickOutsideMouseDownEvent);
    document.body.addEventListener('mouseup', el.clickOutsideMouseUpEvent);
  },
  unmounted(el: HTMLElementClickOutside): void {
    document.body.removeEventListener(
      'mousedown',
      el.clickOutsideMouseDownEvent as EventListener
    );
    document.body.removeEventListener(
      'mouseup',
      el.clickOutsideMouseUpEvent as EventListener
    );
  },
};
