<template>
  <div class="app">
    <scalable is-dashboard>
      <router-view />
    </scalable>
    <template v-if="isProd">
      <small-screen-banner />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isProd } from '@/runtime-env';
import Scalable from '@/components/UI/Layout/Scalable.vue';
import SmallScreenBanner from '@/components/UI/Notifications/SmallScreenBanner.vue';

export default defineComponent({
  name: 'PublicLayout',
  components: {
    SmallScreenBanner,
    Scalable,
  },
  setup() {
    return {
      isProd,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define app

.app {
  min-height: 100vh;
  padding-bottom: var(--global-bottom-spacing);
  box-sizing: border-box;

  background-color: var(--color-app-background);
}
</style>
