<template>
  <div class="incomplete-integration">
    Please complete the integration by adding a
    <router-link
      class="incomplete-integration__action"
      :to="routes.account.info"
    >
      DSP
    </router-link>
    .
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { routes } from '@/router/routes';

export default defineComponent({
  name: 'IncompleteIntegration',
  setup() {
    return {
      routes,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define incomplete-integration

@import '@airgrid/components/styles/shared/typography';

.incomplete-integration {
  @extend %generic-text-styles;

  background-color: var(--color-orange);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-blacks-white);
}
.incomplete-integration__action {
  @extend %generic-text-styles;

  color: inherit;
  cursor: pointer;

  margin-left: 6px;

  font-weight: 600;
}
</style>
