export const setLastAccessedAccount = (accountId: string): void => {
  localStorage.setItem('lastAccessedAccount', accountId);
};

export const getLastAccessedAccount = (): string | null => {
  return localStorage.getItem('lastAccessedAccount');
};

export const removeLastAccessedAccount = (): void => {
  localStorage.removeItem('lastAccessedAccount');
};
