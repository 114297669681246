<template>
  <chip>
    <failure-icon class="error-feedback__icon" />
    <span class="error-feedback__text" data-testid="error-feedback-text">
      <slot />
    </span>
  </chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Chip from '@/components/UI/Chips/Chip.vue';
import FailureIcon from '@/assets/icons/colored/failure.svg';

export default defineComponent({
  name: 'ErrorFeedbackChip',
  components: {
    Chip,
    FailureIcon,
  },
});
</script>

<style lang="scss" scoped>
// @define error-feedback

@import '@airgrid/components/styles/shared/typography';

.error-feedback__text {
  @extend %generic-text-styles;

  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--color-blacks-white);
}
.error-feedback__icon {
  width: 14px;
}
</style>
