import _sortBy from 'lodash/sortBy';
import _merge from 'lodash/merge';
import { format } from 'date-fns';

export * from './filters';

export const convertStylesObjToString = (styles: object) => {
  return Object.entries(styles)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');
};

export const makeMatchingCharactersBolder = (
  matchValue: string,
  item: string,
  styles = { color: 'var(--color-black)', 'font-weight': 600 }
): string => {
  if (matchValue) {
    const re = new RegExp(matchValue, 'gi');
    return item.replace(
      re,
      (value) =>
        `<strong style="${convertStylesObjToString(styles)}">${value}</strong>`
    );
  } else {
    return item;
  }
};

/**
 * Checks if the search query is included in the item's content.
 *
 * This function normalizes both the `itemContent` and `searchQuery` by converting
 * them to lowercase and removing all spaces. It then checks if the prepared
 * `searchQuery` is a substring of the prepared `itemContent`.
 *
 * @param {string} itemContent - The content of the item to search within.
 * @param {string} searchQuery - The search string to check for.
 * @returns {boolean} - Returns `true` if the normalized `searchQuery` is found
 *                      within the normalized `itemContent`; otherwise, `false`.
 */
export const doesSearchMatch = (
  itemContent: string,
  searchQuery: string
): boolean => {
  const prepareStringForSearch = (str: string) =>
    str.toLowerCase().replaceAll(' ', '');

  return prepareStringForSearch(itemContent).includes(
    prepareStringForSearch(searchQuery)
  );
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const mergeDeep = (
  ...objects: Record<string, unknown>[]
): Record<string, unknown> => {
  const isObject = (obj: unknown) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(
          pVal as Record<string, unknown>,
          oVal as Record<string, unknown>
        );
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

export const sortByKey = <T>(arr: T[], key: keyof T): T[] => {
  return _sortBy(arr, [key]);
};

export const copyDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const round = (n: number, precision = 1): number => {
  const m = 10 ** precision;
  return Math.round(n * m) / m;
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const retryWithCooldown = async <T>(
  fn: () => Promise<T>,
  retries = 3,
  error: unknown | null = null
): Promise<T> => {
  if (retries === 0) {
    throw error;
  }
  try {
    return await fn();
  } catch (err) {
    const ONE_SECOND = 1000;
    await sleep(ONE_SECOND);
    return retryWithCooldown(fn, retries - 1, err);
  }
};

export const merge = _merge;

export const formatDateString = (
  date: string | number,
  longForm = false,
  withHours = false
): string => {
  return formatDate(new Date(date), longForm, withHours);
};

/**
 * Formats a given Date object into a readable string format.
 *
 * By default, the function formats the date as '12 May'.
 * - If `longForm` is set to `true`, it formats the date with the full month name and year, e.g., '12 May, 2020'.
 * - If `withHours` is set to `true`, it formats the date with the full month name, time (hour), and year, e.g., '12 May 15:00, 2020'.
 * - Note that `withHours` takes precedence over `longForm`.
 *
 * @param {Date} date - The date object to format.
 * @param {boolean} [longForm=false] - Whether to use the long format with the full month name and year.
 * @param {boolean} [withHours=false] - Whether to include the hour in the formatted string. Takes precedence over `longForm`.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (date: Date, longForm = false, withHours = false) => {
  let formatString = 'd MMM';

  if (longForm) {
    formatString = 'd MMMM, yyyy';
  }

  if (withHours) {
    formatString = 'd MMMM HH:00, yyyy';
  }

  return format(date, formatString);
};
