import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import * as api from '@/store/api';
import {
  AudiencePlanReport,
  DimensionItem,
  MetricMetadata,
  OverviewReportByBuyerItem,
  PerformanceReportResponse,
  PerformanceTablesResponse,
  PublishersPageViews,
  PublishersPageViewsByCountryItem,
  PublishersPageViewsByDayItem,
  YoutubeChannelsReport,
} from '@/store/api';
import {
  capitalizeFirstLetter,
  generateDateRange,
  getSumRevenueOrSpendAndImpressions,
  groupBy,
  logger,
  sortByKey,
} from '@/utils';
import {
  AnalyticsReportDefinitionPayload,
  Audience,
  AudienceConversionRateByDealReport,
  AudienceHealthAvgBidsByDealReport,
  AudienceHealthBidRequestsByDealReport,
  AudienceHealthBlockedDomainsReport,
  AudienceHealthCardsReport,
  AudienceHealthDealsReport,
  AudienceHealthSpendByDealReport,
  BrowserReport,
  ChromeTopicReport,
  DeviceReport,
  DomainReport,
  GeoReport,
  GraphReport,
  HourReport,
  OverviewBuyerCardsReport,
  OverviewFilterOptions,
  OverviewFilterValues,
  OverviewPossiblePeriods,
  OverviewReportByBrandItem,
  OverviewReportByDayItem,
  OverviewReportByPublisherItem,
  OverviewReportBySellerItem,
  OverviewReportType,
  SimilarUrlsResult,
  TrendReport,
} from 'shared-types';
import { useAccountStore } from '@/store/modules/account/account-store';
import { RequestAborted } from '@/store/errors/ClientError';

export const useAnalyticsStore = defineStore('analytics-store', () => {
  const accountStore = useAccountStore();
  const overviewReportByDay = ref<OverviewReportByDayItem[] | undefined>();
  const isOverviewReportByDayLoaded = ref(false);

  const overviewReportByPublisher = ref<
    OverviewReportByPublisherItem[] | undefined
  >();
  const isOverviewReportByPublisherLoaded = ref(false);

  const overviewReportBySeller = ref<
    OverviewReportBySellerItem[] | undefined
  >();
  const isOverviewReportBySellerLoaded = ref(false);

  const overviewReportByBrand = ref<OverviewReportByBrandItem[] | undefined>();
  const isOverviewReportByBrandLoaded = ref(false);

  const overviewFilterValues = ref<
    Omit<OverviewFilterValues, 'reportIntervalDays'> | undefined
  >();
  const isOverviewFilterValuesLoaded = ref(false);

  const overviewCardsReport = ref<OverviewBuyerCardsReport | undefined>();
  const isOverviewCardsReportLoaded = ref(false);

  const audienceHealthDealsReport = ref<AudienceHealthDealsReport>();
  const isAudienceHealthDealsReportLoaded = ref(false);

  const isAudienceHealthCardsReportLoaded = ref(false);

  const audienceHealthCardsReport = ref<
    AudienceHealthCardsReport | undefined
  >();

  const isAudienceHealthBlockedDomainsReportLoaded = ref(false);

  const audienceHealthBlockedDomainsReport = ref<
    AudienceHealthBlockedDomainsReport | undefined
  >();

  const isAudienceHealthSpendByDealReportLoaded = ref(false);

  const audienceHealthSpendByDealReport = ref<
    AudienceHealthSpendByDealReport[] | undefined
  >();

  const isAudienceHealthBidRequestsByDealReportLoaded = ref(false);

  const audienceHealthBidRequestsByDealReport = ref<
    AudienceHealthBidRequestsByDealReport[] | undefined
  >();

  const isAudienceHealthAvgBidsByDealReportLoaded = ref(false);

  const audienceHealthAvgBidsByDealReport = ref<
    AudienceHealthAvgBidsByDealReport[] | undefined
  >();

  const isAudienceConversionRateByDealReportLoaded = ref(false);
  const currentAudienceIdForConversionRateReport = ref<string | undefined>(
    undefined
  );

  const audienceCumulativeConversionRateByDealReport = ref<
    AudienceConversionRateByDealReport[] | undefined
  >();

  const audienceConversionRateByDealReport = ref<
    AudienceConversionRateByDealReport[] | undefined
  >();

  const isAudienceHealthReportRefreshed = ref(true);

  const isAudiencePlanLoaded = ref(false);

  const loadOverviewReportByDay = async () => {
    isOverviewReportByDayLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getOverviewReport({
        accountId: accountId,
        reportType: OverviewReportType.BY_DAY,
        filterOptions: overviewSelectedFilterValues.value,
      });
      overviewReportByDay.value = report as OverviewReportByDayItem[];
    } catch (err) {
      overviewReportByDay.value = [];
      throw err;
    } finally {
      isOverviewReportByDayLoaded.value = true;
    }
  };

  const loadOverviewReportByPublisher = async () => {
    isOverviewReportByPublisherLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getOverviewReport({
        accountId: accountId,
        reportType: OverviewReportType.BY_PUBLISHER,
        filterOptions: overviewSelectedFilterValues.value,
      });
      overviewReportByPublisher.value =
        report as OverviewReportByPublisherItem[];
    } catch (err) {
      overviewReportByPublisher.value = [];
      throw err;
    } finally {
      isOverviewReportByPublisherLoaded.value = true;
    }
  };

  const loadOverviewReportBySeller = async () => {
    isOverviewReportBySellerLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getOverviewReport({
        accountId: accountId,
        reportType: OverviewReportType.BY_SELLER,
        filterOptions: overviewSelectedFilterValues.value,
      });
      overviewReportBySeller.value = report as OverviewReportBySellerItem[];
    } catch (err) {
      overviewReportBySeller.value = [];
      throw err;
    } finally {
      isOverviewReportBySellerLoaded.value = true;
    }
  };

  const loadOverviewReportByBrand = async () => {
    isOverviewReportByBrandLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getOverviewReport({
        accountId: accountId,
        reportType: OverviewReportType.BY_BRAND,
        filterOptions: overviewSelectedFilterValues.value,
      });
      overviewReportByBrand.value = report as OverviewReportByBrandItem[];
    } catch (err) {
      overviewReportByBrand.value = [];
      throw err;
    } finally {
      isOverviewReportByBrandLoaded.value = true;
    }
  };

  const isOverviewReportByBuyerLoaded = ref(false);
  const overviewReportByBuyer = ref<OverviewReportByBuyerItem[] | undefined>();

  const loadOverviewReportByBuyer = async () => {
    isOverviewReportByBuyerLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getOverviewReport({
        accountId: accountId,
        reportType: OverviewReportType.BY_BUYER,
        filterOptions: {
          ...overviewSelectedFilterValues.value,
        },
      });
      overviewReportByBuyer.value = report as OverviewReportByBuyerItem[];
    } catch (err) {
      overviewReportByBuyer.value = [];
      throw err;
    } finally {
      isOverviewReportByBuyerLoaded.value = true;
    }
  };

  const getOverviewReportByBuyer = computed(() => () => {
    if (!overviewReportByBuyer.value)
      throw new Error('Overview report by buyer not loaded');

    if (
      overviewReportByBuyer.value === undefined ||
      overviewReportByBuyer.value.length === 0
    )
      return [];

    const buyerSeries = overviewReportByBuyer.value.map((item) => {
      const { spend } = item;

      return {
        name: item.buyerName,
        value: spend,
      };
    });

    const TOP_BUYERS_BY_SPEND = 20;
    return buyerSeries.slice(0, TOP_BUYERS_BY_SPEND);
  });

  const loadOverviewFilterValues = async () => {
    isOverviewFilterValuesLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const filterValues = await api.getOverviewFilterValues(accountId);
      overviewFilterValues.value = filterValues;
    } catch (err) {
      overviewFilterValues.value = {
        publishers: [],
        sellers: [],
        mediaTypes: [],
      };
      throw err;
    } finally {
      isOverviewFilterValuesLoaded.value = true;
    }
  };

  const unsetAnalytics = () => {
    overviewReportByDay.value = undefined;
    overviewReportByPublisher.value = undefined;
    overviewReportBySeller.value = undefined;
    overviewReportByBrand.value = undefined;
    overviewReportByBuyer.value = undefined;
    overviewFilterValues.value = undefined;
    overviewCardsReport.value = undefined;
    isOverviewReportByDayLoaded.value = false;
    isOverviewReportByBrandLoaded.value = false;
    isOverviewReportByPublisherLoaded.value = false;
    isOverviewReportBySellerLoaded.value = false;
    isOverviewFilterValuesLoaded.value = false;
    isOverviewCardsReportLoaded.value = false;
    isOverviewReportByBuyerLoaded.value = false;
    audienceHealthCardsReport.value = undefined;
    isAudienceHealthCardsReportLoaded.value = false;
    audienceHealthDealsReport.value = undefined;
    isAudienceHealthDealsReportLoaded.value = false;
    audienceHealthSpendByDealReport.value = undefined;
    isAudienceHealthSpendByDealReportLoaded.value = false;
  };

  const getOverviewReportByDay = computed(() => () => {
    if (!overviewReportByDay.value)
      throw new Error('Overview report by day not loaded');

    if (overviewReportByDay.value.length === 0) return [];

    const daywiseAnalytics = groupBy(overviewReportByDay.value, 'day');

    const days = generateDateRange(
      overviewSelectedFilterValues.value.reportIntervalDays
    );

    const overviewByDay = days.map((day) => ({
      day,
      ...getSumRevenueOrSpendAndImpressions(daywiseAnalytics[day]),
    }));

    return sortByKey(overviewByDay, 'day');
  });

  const getOverviewReportByPublisher = computed(() => () => {
    if (!overviewReportByPublisher.value)
      throw new Error('Overview report by publisher not loaded');

    if (
      overviewReportByPublisher.value === undefined ||
      overviewReportByPublisher.value.length === 0
    )
      return [];

    const publishersSeries = overviewReportByPublisher.value.map((item) => {
      const { revenue } = item;

      return {
        name: item.publisherName,
        value: revenue,
      };
    });

    const TOP_PUBLISHERS_BY_SPEND = 20;
    return publishersSeries.slice(0, TOP_PUBLISHERS_BY_SPEND);
  });

  const getOverviewReportBySeller = computed(() => () => {
    if (!overviewReportBySeller.value)
      throw new Error('Overview report by seller not loaded');

    if (
      overviewReportBySeller.value === undefined ||
      overviewReportBySeller.value.length === 0
    ) {
      return [];
    }

    const sellersSeries = overviewReportBySeller.value.map((item) => {
      const { spend } = item;

      return {
        name: item.sellerName,
        value: spend,
      };
    });

    const TOP_SELLERS_BY_SPEND = 20;
    return sellersSeries.slice(0, TOP_SELLERS_BY_SPEND);
  });

  const getOverviewReportByBrand = computed(() => () => {
    if (!overviewReportByBrand.value)
      throw new Error('Overview report by brand not loaded');

    if (
      overviewReportByBrand.value === undefined ||
      overviewReportByBrand.value.length === 0
    )
      return [];

    const brandsSeries = overviewReportByBrand.value.map((item) => {
      const { spend } = item;

      return {
        name: item.brandName,
        value: spend ?? 0,
      };
    });

    const TOP_BRANDS_BY_SPEND = 20;
    return brandsSeries.slice(0, TOP_BRANDS_BY_SPEND);
  });

  const loadOverviewCardsReport = async () => {
    isOverviewCardsReportLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getOverviewCardsReport({
        accountId,
        filterOptions: overviewSelectedFilterValues.value,
      });
      overviewCardsReport.value = report;
    } catch (err) {
      overviewCardsReport.value = {} as OverviewBuyerCardsReport;
      throw err;
    } finally {
      isOverviewCardsReportLoaded.value = true;
    }
  };

  const getOverviewCardsReport = computed(() => () => {
    if (!isOverviewCardsReportLoaded.value) {
      throw new Error('Overview cards report not loaded');
    }
    return overviewCardsReport.value;
  });

  const getOverviewFilterValues = computed(() => () => {
    if (!overviewFilterValues.value)
      throw new Error('Overview filter values not loaded');

    type OverviewFilterOptions = {
      publishers: { value: number | undefined; label: string }[];
      sellers: { value: number | undefined; label: string }[];
      mediaTypes: { value: string | undefined; label: string }[];
      reportIntervalDays: { value: number; label: string }[];
    };

    const filterOptions: OverviewFilterOptions = {
      publishers: [{ value: undefined, label: 'All publishers' }],
      sellers: [{ value: undefined, label: 'All sellers' }],
      mediaTypes: [
        { value: undefined, label: 'All media' },
        ...overviewFilterValues.value.mediaTypes.map((mediaType) => ({
          value: mediaType,
          label: capitalizeFirstLetter(mediaType),
        })),
      ],
      reportIntervalDays: [7, 30, 90].map((period) => ({
        value: period,
        label: `Last ${period} days`,
      })),
    };

    if (overviewFilterValues.value.publishers !== undefined) {
      filterOptions.publishers = [
        ...filterOptions.publishers,
        ...overviewFilterValues.value.publishers.map((publisher) => ({
          value: publisher.id,
          label: publisher.name,
        })),
      ];
    }

    if (overviewFilterValues.value.sellers !== undefined) {
      filterOptions.sellers = [
        ...filterOptions.sellers,
        ...overviewFilterValues.value.sellers.map((seller) => ({
          value: seller.id,
          label: seller.name,
        })),
      ];
    }

    return filterOptions;
  });

  const overviewSelectedFilterValues: Ref<OverviewFilterOptions> = ref({
    publisherId: undefined,
    sellerMemberId: undefined,
    mediaType: undefined,
    reportIntervalDays: 30 as OverviewPossiblePeriods,
  });

  const getOverviewSelectedFilterValues = computed(() => () => {
    return overviewSelectedFilterValues.value;
  });

  const isReportLoading = ref(false);

  const setOverviewSelectedFilterValues = async <
    K extends keyof OverviewFilterOptions
  >(
    key: K,
    value: OverviewFilterOptions[K]
  ) => {
    if (value === overviewSelectedFilterValues.value[key]) return;

    if (
      key === 'reportIntervalDays' &&
      value !== undefined &&
      typeof value === 'number' &&
      value <= 0
    )
      throw new Error('reportIntervalDays must be greater than 0');

    overviewSelectedFilterValues.value = {
      ...overviewSelectedFilterValues.value,
      [key]: value,
    };

    isReportLoading.value = true;
    const loadPromises = [];
    if (isOverviewCardsReportLoaded.value) {
      loadPromises.push(loadOverviewCardsReport());
    }
    if (isOverviewReportByDayLoaded.value) {
      loadPromises.push(loadOverviewReportByDay());
    }
    if (isOverviewReportByPublisherLoaded.value) {
      loadPromises.push(loadOverviewReportByPublisher());
    }
    if (isOverviewReportBySellerLoaded.value) {
      loadPromises.push(loadOverviewReportBySeller());
    }
    if (isOverviewReportByBrandLoaded.value) {
      loadPromises.push(loadOverviewReportByBrand());
    }
    if (isOverviewReportByBuyerLoaded.value) {
      loadPromises.push(loadOverviewReportByBuyer());
    }

    await Promise.all(loadPromises);
    isReportLoading.value = false;
  };

  const loadOverviewData = async () => {
    const loadPromises = [loadOverviewCardsReport(), loadOverviewReportByDay()];

    if (isOverviewReportBySellerLoaded.value) {
      loadPromises.push(loadOverviewReportBySeller());
    }
    if (isOverviewReportByPublisherLoaded.value) {
      loadPromises.push(loadOverviewReportByPublisher());
    }
    if (isOverviewReportByBrandLoaded.value) {
      loadPromises.push(loadOverviewReportByBrand());
    }
    if (isOverviewReportByBuyerLoaded.value) {
      loadPromises.push(loadOverviewReportByBuyer());
    }

    await Promise.all(loadPromises);
  };

  const loadAudienceHealthDealsReport = async (audienceId: string) => {
    isAudienceHealthDealsReportLoaded.value = false;

    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthDealsReport({
        audienceId,
        accountId,
      });
      audienceHealthDealsReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthDealsReportLoaded.value = true;
    }
  };

  const getAudienceHealthDealsReport = computed(() => () => {
    if (!isAudienceHealthDealsReportLoaded.value) {
      throw new Error('Health overview deals report not loaded');
    }
    return audienceHealthDealsReport.value;
  });

  const loadAudienceHealthCardsReport = async (audienceId: string) => {
    isAudienceHealthCardsReportLoaded.value = false;

    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthCardsReport({
        audienceId,
        accountId,
      });
      audienceHealthCardsReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthCardsReportLoaded.value = true;
    }
  };

  const getAudienceHealthCardsReport = computed(() => () => {
    if (!isAudienceHealthCardsReportLoaded.value) {
      throw new Error('Health overview deals report not loaded');
    }
    return audienceHealthCardsReport.value;
  });

  const loadAudienceHealthSpendByDealReport = async (audienceId: string) => {
    isAudienceHealthSpendByDealReportLoaded.value = false;

    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthSpendByDealReport({
        audienceId,
        accountId,
      });

      audienceHealthSpendByDealReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthSpendByDealReportLoaded.value = true;
    }
  };

  const loadAudienceHealthBlockedDomainsReport = async (audienceId: string) => {
    isAudienceHealthBlockedDomainsReportLoaded.value = false;

    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthBlockedDomainsReport({
        audienceId,
        accountId,
      });
      audienceHealthBlockedDomainsReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthBlockedDomainsReportLoaded.value = true;
    }
  };

  const getAudienceHealthBlockedDomainsReport = computed(() => () => {
    if (!isAudienceHealthBlockedDomainsReportLoaded.value) {
      throw new Error('Health blocked domains report not loaded');
    }
    return audienceHealthBlockedDomainsReport.value;
  });

  const getAudienceHealthSpendByDealReport = computed(() => () => {
    if (!isAudienceHealthSpendByDealReportLoaded.value) {
      throw new Error('Health overview deals report not loaded');
    }

    if (audienceHealthSpendByDealReport.value === undefined) {
      return [];
    }

    return audienceHealthSpendByDealReport.value;
  });

  const loadAudienceHealthBidRequestsByDealReport = async (
    audienceId: string
  ) => {
    isAudienceHealthBidRequestsByDealReportLoaded.value = false;

    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthBidRequestsByDealReport({
        audienceId,
        accountId,
      });

      audienceHealthBidRequestsByDealReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthBidRequestsByDealReportLoaded.value = true;
    }
  };

  const loadAudienceHealthAvgBidsByDealReport = async (audienceId: string) => {
    isAudienceHealthAvgBidsByDealReportLoaded.value = false;

    try {
      const seats = accountStore.getAccount()?.buyerPlatformSeats;
      if (seats === undefined || seats.length === 0) return;

      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceHealthAvgBidsByDealReport({
        audienceId,
        accountId,
      });

      audienceHealthAvgBidsByDealReport.value = report;
    } catch (err) {
      logger.error(err);
    } finally {
      isAudienceHealthAvgBidsByDealReportLoaded.value = true;
    }
  };

  const loadAudienceConversionRateByDealReport = async (audienceId: string) => {
    if (
      isAudienceConversionRateByDealReportLoaded.value === true &&
      currentAudienceIdForConversionRateReport.value === audienceId
    ) {
      return;
    }

    isAudienceConversionRateByDealReportLoaded.value = false;
    try {
      const accountId = accountStore.getSelectedAccountId();

      const report = await api.getAudienceConversionRateByDealReport({
        audienceId,
        accountId,
      });

      // NOTE: A single AudienceConversionRateByDealReport returns CVR and Cumulative CVR fields in a single response.
      // Here we split these values and create two separate reports for CVR and Cumulative CVR charts.
      const cvrReport: AudienceConversionRateByDealReport[] = [];
      const cumulativeCvrReport: AudienceConversionRateByDealReport[] = [];
      for (const reportItem of report) {
        const dailyCvrs = reportItem.dailyCvrs.map((item) => ({
          day: item.day,
          value: item.cvr,
        }));
        const dailyCumulativeCvrs = reportItem.dailyCvrs.map((item) => ({
          day: item.day,
          value: item.cumulativeCvr,
        }));

        cvrReport.push({
          dealName: reportItem.dealName,
          dailyCvrs,
        });
        cumulativeCvrReport.push({
          dealName: reportItem.dealName,
          dailyCvrs: dailyCumulativeCvrs,
        });
      }

      audienceConversionRateByDealReport.value = cvrReport;
      audienceCumulativeConversionRateByDealReport.value = cumulativeCvrReport;
    } catch (err) {
      logger.error(err);
    } finally {
      currentAudienceIdForConversionRateReport.value = audienceId;
      isAudienceConversionRateByDealReportLoaded.value = true;
    }
  };

  const getAudienceHealthBidRequestsByDealReport = computed(() => () => {
    if (!isAudienceHealthBidRequestsByDealReportLoaded.value) {
      throw new Error('Health overview deals report not loaded');
    }

    if (audienceHealthBidRequestsByDealReport.value === undefined) {
      return [];
    }

    return audienceHealthBidRequestsByDealReport.value;
  });

  const getAudienceHealthAvgBidsByDealReport = computed(() => () => {
    if (!isAudienceHealthAvgBidsByDealReportLoaded.value) {
      throw new Error('Health overview deals report not loaded');
    }

    if (audienceHealthAvgBidsByDealReport.value === undefined) {
      return [];
    }

    return audienceHealthAvgBidsByDealReport.value;
  });

  const getAudienceCumulativeConversionRateByDealReport = computed(() => () => {
    if (!isAudienceConversionRateByDealReportLoaded.value) {
      throw new Error('Audience conversion rate by deal report not loaded');
    }

    if (audienceCumulativeConversionRateByDealReport.value === undefined) {
      return [];
    }

    return audienceCumulativeConversionRateByDealReport.value;
  });

  const getAudienceConversionRateByDealReport = computed(() => () => {
    if (!isAudienceConversionRateByDealReportLoaded.value) {
      throw new Error('Audience conversion rate by deal report not loaded');
    }

    if (audienceConversionRateByDealReport.value === undefined) {
      return [];
    }

    return audienceConversionRateByDealReport.value;
  });

  const trendReport = ref<TrendReport | undefined>();
  const isTrendReportLoading = ref(false);
  const abortTrendReportController = ref<AbortController | undefined>();

  const loadTrendReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      trendReport.value = undefined;
      return;
    }
    if (abortTrendReportController.value) {
      abortTrendReportController.value.abort();
    }

    isTrendReportLoading.value = true;
    abortTrendReportController.value = new AbortController();
    try {
      const report = await api.getTrendReport({
        audienceDefinition,
        intervalDays: 45,
        abortSignal: abortTrendReportController.value.signal,
      });

      trendReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isTrendReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isTrendReportLoading.value = false;
  };

  const domainReport = ref<DomainReport | undefined>();
  const isDomainReportLoading = ref(false);
  const abortDomainReportController = ref<AbortController | undefined>();

  const loadDomainReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      domainReport.value = undefined;
      return;
    }
    if (abortDomainReportController.value) {
      abortDomainReportController.value.abort();
    }
    isDomainReportLoading.value = true;
    abortDomainReportController.value = new AbortController();
    try {
      const report = await api.getDomainReport({
        audienceDefinition,
        abortSignal: abortDomainReportController.value.signal,
      });
      domainReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isDomainReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isDomainReportLoading.value = false;
  };

  const deviceReport = ref<DeviceReport | undefined>();
  const isDeviceReportLoading = ref(false);
  const abortDeviceReportController = ref<AbortController | undefined>();

  const loadDeviceReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      deviceReport.value = undefined;
      return;
    }
    if (abortDeviceReportController.value) {
      abortDeviceReportController.value.abort();
    }

    isDeviceReportLoading.value = true;
    abortDeviceReportController.value = new AbortController();
    try {
      const report = await api.getDeviceReport({
        audienceDefinition,
        abortSignal: abortDeviceReportController.value.signal,
      });
      deviceReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isDeviceReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isDeviceReportLoading.value = false;
  };

  const browserReport = ref<BrowserReport | undefined>();
  const isBrowserReportLoading = ref(false);
  const abortBrowserReportController = ref<AbortController | undefined>();

  const loadBrowserReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      browserReport.value = undefined;
      return;
    }
    if (abortBrowserReportController.value) {
      abortBrowserReportController.value.abort();
    }

    isBrowserReportLoading.value = true;
    abortBrowserReportController.value = new AbortController();
    try {
      const report = await api.getBrowserReport({
        audienceDefinition,
        abortSignal: abortBrowserReportController.value.signal,
      });
      browserReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isBrowserReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isBrowserReportLoading.value = false;
  };

  const geoReport = ref<GeoReport | undefined>();
  const isGeoReportLoading = ref(false);
  const abortGeoReportController = ref<AbortController | undefined>();

  const loadGeoReport = async (input: {
    audienceDefinition: AnalyticsReportDefinitionPayload;
    countryCode: string;
  }) => {
    if (
      input.audienceDefinition.tags.length === 0 &&
      input.audienceDefinition.searchTerms.length === 0
    ) {
      geoReport.value = undefined;
      return;
    }

    if (abortGeoReportController.value) {
      abortGeoReportController.value.abort();
    }

    isGeoReportLoading.value = true;
    abortGeoReportController.value = new AbortController();
    try {
      const report = await api.getGeoReport({
        ...input,
        abortSignal: abortGeoReportController.value.signal,
      });
      geoReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isGeoReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isGeoReportLoading.value = false;
  };

  const hourReport = ref<HourReport | undefined>();
  const isHourReportLoading = ref(false);
  const abortHourReportController = ref<AbortController | undefined>();

  const loadHourReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      hourReport.value = undefined;
      return;
    }
    if (abortHourReportController.value) {
      abortHourReportController.value.abort();
    }

    isHourReportLoading.value = true;
    abortHourReportController.value = new AbortController();
    try {
      const report = await api.getHourReport({
        audienceDefinition,
        abortSignal: abortHourReportController.value.signal,
      });
      hourReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isHourReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isHourReportLoading.value = false;
  };

  const chromeTopicReport = ref<ChromeTopicReport | undefined>();
  const isChromeTopicReportLoading = ref(false);
  const abortChromeTopicReportController = ref<AbortController | undefined>();

  const loadChromeTopicReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      chromeTopicReport.value = undefined;
      return;
    }

    if (abortChromeTopicReportController.value) {
      abortChromeTopicReportController.value.abort();
    }

    isChromeTopicReportLoading.value = true;
    abortChromeTopicReportController.value = new AbortController();
    try {
      const report = await api.getChromeTopicReport({
        audienceDefinition,
        abortSignal: abortChromeTopicReportController.value.signal,
      });
      chromeTopicReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isChromeTopicReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isChromeTopicReportLoading.value = false;
  };

  const youtubeChannelsReport = ref<YoutubeChannelsReport | undefined>();
  const isYoutubeChannelsReportLoading = ref(false);
  const abortYoutubeChannelsReportController = ref<
    AbortController | undefined
  >();

  const loadYoutubeChannelsReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    if (
      audienceDefinition.tags.length === 0 &&
      audienceDefinition.searchTerms.length === 0
    ) {
      youtubeChannelsReport.value = undefined;
      return;
    }

    if (abortYoutubeChannelsReportController.value) {
      abortYoutubeChannelsReportController.value.abort();
    }

    isYoutubeChannelsReportLoading.value = true;
    abortYoutubeChannelsReportController.value = new AbortController();
    try {
      const report = await api.getYoutubeChannelsReport({
        audienceDefinition,
        abortSignal: abortYoutubeChannelsReportController.value.signal,
      });
      youtubeChannelsReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isYoutubeChannelsReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isYoutubeChannelsReportLoading.value = false;
  };

  const isYoutubeChannelsDownloadReportLoading = ref(false);
  const getYoutubeChannelsDownloadReport = async (
    audienceDefinition: AnalyticsReportDefinitionPayload
  ) => {
    isYoutubeChannelsDownloadReportLoading.value = true;
    try {
      const report = await api.getYoutubeChannelsReport({
        audienceDefinition,
        isDownload: true,
      });

      return report;
    } finally {
      isYoutubeChannelsDownloadReportLoading.value = false;
    }
  };

  const sortedDefinitions = ref<string[]>([]);

  const setSortedDefinitions = (items: string[]) => {
    sortedDefinitions.value = items;
  };

  const similarUrls = ref<SimilarUrlsResult[]>([]);
  const suggestedAudiences = ref<Audience[]>([]);

  const getAudiencePlan = async (input: {
    hash: string;
    countryCode: string;
  }) => {
    isAudiencePlanLoaded.value = false;
    const report = await api.getAudiencePlan(input);

    if (report) {
      trendReport.value = report.trendReport;
      domainReport.value = report.domainReport;
      chromeTopicReport.value = report.chromeTopicReport;
      browserReport.value = report.browserReport;
      hourReport.value = report.hourReport;
      deviceReport.value = report.deviceReport;
      geoReport.value = report.geoReport;
      graphReport.value = report.graphReport;
      similarUrls.value = report.similarUrls || [];
      suggestedAudiences.value = report.suggestedAudiences || [];
      youtubeChannelsReport.value = report.youtubeChannelsReport || [];
    }
    isAudiencePlanLoaded.value = true;
    return report;
  };

  const getAudiencePlanGeoReport = async (input: {
    hash: string;
    countryCode: string;
  }) => {
    isGeoReportLoading.value = true;
    const report = await api.getAudiencePlan({
      ...input,
      reports: [AudiencePlanReport.GEO],
    });

    if (report) {
      report.geoReport && (geoReport.value = report.geoReport);
    }
    isGeoReportLoading.value = false;
    return report;
  };

  const performanceReportTables = ref<
    PerformanceTablesResponse[] | undefined
  >();

  const isPerformanceReportTablesLoading = ref(true);

  const loadPerformanceReportTables = async () => {
    isPerformanceReportTablesLoading.value = true;
    try {
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getPerformanceReportTables(accountId);

      performanceReportTables.value = report;
    } finally {
      isPerformanceReportTablesLoading.value = false;
    }
  };

  const performanceReport = ref<PerformanceReportResponse | undefined>();
  const isPerformanceReportLoading = ref(true);
  const performanceReportMetricsMetadata = ref(
    new Map<string, MetricMetadata>()
  );
  const performanceReportMetricFilter = ref<string | undefined>();
  const performanceReportTableFilter = ref<string | undefined>();
  const performanceReportDimension = ref<DimensionItem[]>([]);

  const performanceReportDimensionsFilter = ref<
    Record<
      string,
      {
        friendlyName: string;
        selectedItems: string[];
        exclude: boolean;
      }
    >
  >({});

  const updatePerformanceReportDimensionFilter = (
    dimension: string,
    item: string,
    friendlyName: string
  ) => {
    const dimensionToUpdate =
      performanceReportDimensionsFilter.value[dimension];

    if (!dimensionToUpdate) {
      performanceReportDimensionsFilter.value[dimension] = {
        friendlyName: friendlyName,
        selectedItems: [item],
        exclude: false,
      };
    } else {
      if (dimensionToUpdate.selectedItems.includes(item)) {
        dimensionToUpdate.selectedItems =
          dimensionToUpdate.selectedItems.filter(
            (selectedItem) => selectedItem !== item
          );
      } else {
        dimensionToUpdate.selectedItems.push(item);
      }

      if (dimensionToUpdate.selectedItems.length === 0) {
        delete performanceReportDimensionsFilter.value[dimension];
      }
    }
  };

  const resetPerformanceReportDimensionFilter = () => {
    performanceReportDimensionsFilter.value = {};
  };

  const removePerformanceReportDimensionFilter = (dimension: string) => {
    delete performanceReportDimensionsFilter.value[dimension];
  };

  const getPerformanceReportDimensionFilterPayload = () => {
    const dimensionFilterPayload = Object.keys(
      performanceReportDimensionsFilter.value
    ).map((key) => {
      if (performanceReportDimensionsFilter.value[key].exclude) {
        return {
          dimension: key,
          notIn: performanceReportDimensionsFilter.value[key].selectedItems,
        };
      } else {
        return {
          dimension: key,
          in: performanceReportDimensionsFilter.value[key].selectedItems,
        };
      }
    });

    return dimensionFilterPayload;
  };

  const abortPerformanceReportController = ref<AbortController | undefined>();

  const loadPerformanceReport = async (audienceId: string) => {
    if (abortPerformanceReportController.value) {
      abortPerformanceReportController.value.abort();
    }
    isPerformanceReportLoading.value = true;
    abortPerformanceReportController.value = new AbortController();
    try {
      const accountId = accountStore.getSelectedAccountId();

      const dimensionFilters = getPerformanceReportDimensionFilterPayload();
      const filter = {
        dimensionFilters,
        table: performanceReportTableFilter.value,
        metric: performanceReportMetricFilter.value,
      };

      const report = await api.getPerformanceReport({
        audienceId,
        accountId,
        filter,
        abortSignal: abortPerformanceReportController.value.signal,
      });

      performanceReport.value = report;
      performanceReportDimension.value = report.dimensions;

      report.metrics.metadata.forEach((item) => {
        performanceReportMetricsMetadata.value.set(item.name, {
          name: item.name,
          friendlyName: item.friendlyName,
          totalValue: item.totalValue,
          type: item.type,
          benchmarkValue: item.benchmarkValue,
        });
      });
    } catch (err) {
      if (err instanceof RequestAborted) return;
      throw err;
    }
    isPerformanceReportLoading.value = false;
  };

  const getMetricMetadata = computed(() => (metricName: string) => {
    const metric = performanceReportMetricsMetadata.value.get(metricName);
    if (!metric || !metric.friendlyName) {
      throw new Error(`Friendly name for ${metricName} metric not found!`);
    }
    return metric;
  });

  const clearPerformanceReport = () => {
    performanceReport.value = undefined;
    performanceReportMetricsMetadata.value = new Map<string, MetricMetadata>();
    performanceReportMetricFilter.value = undefined;
    performanceReportTableFilter.value = undefined;
    performanceReportDimensionsFilter.value = {};
    performanceReportDimension.value = [];
  };

  const graphReport = ref<GraphReport | undefined>();
  const isGraphReportLoading = ref(false);
  const abortGraphReportController = ref<AbortController | undefined>();

  const loadGraphReport = async (tags: string[]) => {
    if (tags.length === 0) {
      graphReport.value = undefined;
      return;
    }

    if (abortGraphReportController.value) {
      abortGraphReportController.value.abort();
    }

    isGraphReportLoading.value = true;
    abortGraphReportController.value = new AbortController();
    try {
      const report = await api.getGraphReport({
        tags,
        abortSignal: abortGraphReportController.value.signal,
      });
      graphReport.value = report;
    } catch (err) {
      if (err instanceof RequestAborted) return;
      isGraphReportLoading.value = false;
      logger.error(err);
      throw err;
    }
    isGraphReportLoading.value = false;
  };

  const isAttributionPixelGroupDownloadReportLoaded = ref(true);
  const getAttributionPixelGroupDownloadReport = async (input: {
    attributionPixelGroupId: number;
  }) => {
    isAttributionPixelGroupDownloadReportLoaded.value = false;
    try {
      const { attributionPixelGroupId } = input;
      const accountId = accountStore.getSelectedAccountId();
      const report = await api.getAttributionPixelGroupDownloadReport({
        attributionPixelGroupId,
        accountId,
      });

      return report;
    } finally {
      isAttributionPixelGroupDownloadReportLoaded.value = true;
    }
  };

  const clearTrendReports = () => {
    trendReport.value = undefined;
    domainReport.value = undefined;
    deviceReport.value = undefined;
    browserReport.value = undefined;
    geoReport.value = undefined;
    hourReport.value = undefined;
    graphReport.value = undefined;
  };

  const isPublisherPageViewsLoaded = ref(false);
  const publishersPageViews = ref<PublishersPageViews | undefined>();

  const loadPublisherPageViews = async () => {
    try {
      isPublisherPageViewsLoaded.value = false;
      publishersPageViews.value = await api.getPublisherPageViewsReports();
    } finally {
      isPublisherPageViewsLoaded.value = true;
    }
  };

  const getPublisherPageViews = (
    id: string
  ): {
    byDay: PublishersPageViewsByDayItem[];
    byCountry: PublishersPageViewsByCountryItem[];
  } => {
    if (publishersPageViews.value === undefined) {
      throw new Error('Publisher page views data is not loaded!');
    }
    const pageViews = publishersPageViews.value[id];

    if (!pageViews) {
      return {
        byDay: [],
        byCountry: [],
      };
    }

    return pageViews;
  };

  return {
    overviewReportByDay,
    isOverviewReportByDayLoaded,
    isOverviewReportByPublisherLoaded,
    isOverviewReportBySellerLoaded,
    isOverviewReportByBrandLoaded,
    getOverviewReportByDay,
    getOverviewReportByPublisher,
    getOverviewReportBySeller,
    getOverviewReportByBrand,
    loadOverviewReportByDay,
    loadOverviewReportByPublisher,
    loadOverviewReportBySeller,
    loadOverviewReportByBrand,
    unsetAnalytics,
    isOverviewFilterValuesLoaded,
    loadOverviewFilterValues,
    getOverviewFilterValues,
    overviewSelectedFilterValues,
    getOverviewSelectedFilterValues,
    setOverviewSelectedFilterValues,
    isReportLoading,
    overviewCardsReport,
    loadOverviewCardsReport,
    getOverviewCardsReport,
    isOverviewCardsReportLoaded,
    isAudienceHealthDealsReportLoaded,
    loadAudienceHealthDealsReport,
    getAudienceHealthDealsReport,
    loadAudienceHealthCardsReport,
    getAudienceHealthCardsReport,
    isAudienceHealthCardsReportLoaded,
    loadAudienceHealthSpendByDealReport,
    getAudienceHealthSpendByDealReport,
    isAudienceHealthSpendByDealReportLoaded,
    loadAudienceHealthBidRequestsByDealReport,
    getAudienceHealthBidRequestsByDealReport,
    isAudienceHealthBidRequestsByDealReportLoaded,
    loadAudienceHealthAvgBidsByDealReport,
    getAudienceHealthAvgBidsByDealReport,
    isAudienceHealthAvgBidsByDealReportLoaded,
    loadAudienceConversionRateByDealReport,
    getAudienceCumulativeConversionRateByDealReport,
    getAudienceConversionRateByDealReport,
    isAudienceConversionRateByDealReportLoaded,
    loadAudienceHealthBlockedDomainsReport,
    getAudienceHealthBlockedDomainsReport,
    isAudienceHealthBlockedDomainsReportLoaded,
    isAudienceHealthReportRefreshed,
    loadOverviewData,
    trendReport,
    isTrendReportLoading,
    domainReport,
    isDomainReportLoading,
    loadTrendReport,
    loadDomainReport,
    deviceReport,
    isDeviceReportLoading,
    loadDeviceReport,
    browserReport,
    isBrowserReportLoading,
    loadBrowserReport,
    geoReport,
    isGeoReportLoading,
    loadGeoReport,
    hourReport,
    isHourReportLoading,
    loadHourReport,
    chromeTopicReport,
    isChromeTopicReportLoading,
    loadChromeTopicReport,
    youtubeChannelsReport,
    isYoutubeChannelsReportLoading,
    abortYoutubeChannelsReportController,
    loadYoutubeChannelsReport,
    clearTrendReports,
    sortedDefinitions,
    getAudiencePlan,
    isAudiencePlanLoaded,
    performanceReportTables,
    isPerformanceReportTablesLoading,
    loadPerformanceReportTables,
    performanceReport,
    isPerformanceReportLoading,
    loadPerformanceReport,
    performanceReportMetricsMetadata,
    getMetricMetadata,
    performanceReportMetricFilter,
    performanceReportTableFilter,
    performanceReportDimension,
    performanceReportDimensionsFilter,
    updatePerformanceReportDimensionFilter,
    resetPerformanceReportDimensionFilter,
    removePerformanceReportDimensionFilter,
    clearPerformanceReport,
    graphReport,
    isGraphReportLoading,
    abortGraphReportController,
    loadGraphReport,
    loadOverviewReportByBuyer,
    isOverviewReportByBuyerLoaded,
    getOverviewReportByBuyer,
    getAudiencePlanGeoReport,
    setSortedDefinitions,
    similarUrls,
    suggestedAudiences,
    getAttributionPixelGroupDownloadReport,
    isAttributionPixelGroupDownloadReportLoaded,
    isYoutubeChannelsDownloadReportLoading,
    getYoutubeChannelsDownloadReport,
    isPublisherPageViewsLoaded,
    publishersPageViews,
    loadPublisherPageViews,
    getPublisherPageViews,
  };
});
