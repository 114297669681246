import { Persona, YoutubeChannelsReport } from '@/store/api';

export type ValueOf<T> = T[keyof T];

export enum AudienceType {
  FEED = 'FEED',
  CUSTOM = 'CUSTOM',
  TAXONOMY = 'TAXONOMY',
}

export enum AudienceStatus {
  LIVE = 'LIVE',
  PAUSED = 'PAUSED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  PAUSED_BY_SYSTEM = 'PAUSED_BY_SYSTEM',
}

export enum ActivationMethod {
  EDGE = 'EDGE',
  FULLURL = 'FULLURL',
  GEO = 'GEO',
  PARTIALURL = 'PARTIALURL',
}

export enum DealMetric {
  CTR = 'CTR',
  CPA = 'CPA',
  CPL = 'CPL',
  VCR = 'VCR',
}

export enum MediaType {
  DISPLAY = 'DISPLAY',
  VIDEO = 'VIDEO',
  NATIVE = 'NATIVE',
}

export enum Geography {
  UK = 'UK',
  US = 'US',
  CA = 'CA',
  AU = 'AU',
  IE = 'IE',
  FR = 'FR',
  ES = 'ES',
  IT = 'IT',
  NL = 'NL',
  DE = 'DE',
  BE = 'BE',
  MX = 'MX',
  NZ = 'NZ',
  ZA = 'ZA',
  AE = 'AE',
  SG = 'SG',
  IN = 'IN',
  DK = 'DK',
  NO = 'NO',
  SE = 'SE',
  FI = 'FI',
  PH = 'PH',
  MY = 'MY',
  HK = 'HK',
  TW = 'TW',
  KR = 'KR',
  JP = 'JP',
}

export enum DeviceType {
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  PC = 'PC',
  TV = 'TV',
  GAMECONSOLE = 'GAMECONSOLE',
  STB = 'STB',
  MEDIAPLAYER = 'MEDIAPLAYER',
}

export type AdSize = {
  width: number;
  height: number;
};

export type Metric = {
  name: string;
  value: number;
};

export type Audience = {
  id: string;
  name: string;
  userId: string;
  accountId: string;
  type: AudienceType;
  status: AudienceStatus;
  isHidden: boolean;
  statistics: AudienceStatistic[];
  notifiedInactiveAt: null | string;
  createdAt: string;
  updatedAt: string;
  segments: Segment[];
  attributionPixelGroupId?: number;
};

export type Deal = {
  audienceId: string;
  name: string;
  id: number;
  xandrDealId: number;
  xandrDealName: string;
  xandrLineItemId: number;
  xandrLineItemName: string;
  xandrProfileId: number;
  buyerPlatformSeats: DspBuyerPlatformSeat[];
  xandrMetric: DealMetric;
  xandrGeography: Geography[];
  xandrFloorPrice: number;
  mediaType: MediaType;
  xandrAdSizes: AdSize[];
  xandrDeviceTypes: DeviceType[];
  activationMethod: ActivationMethod;
  xandrViewabilityRate?: number;
  xandrVideoCompletionRate?: number;
};

export type AudienceCreationResult = {
  name: string;
  accountId: string;
  type: AudienceType;
  mediaType: MediaType;
  geography: Geography[];
  metric: DealMetric;
  scale: AudienceScale;
  viewabilityRate?: number;
  videoCompletionRate?: number;
  tags: string[];
  searchTerms: string[];
};

// TODO: Remove ACCOUNT_ACCESS_SELLER_ACCOUNT_LIST
export enum Permission {
  ACCOUNT_ACCESS_ALL = 'ACCOUNT_ACCESS_ALL',
  ACCOUNT_CREATE_NEW = 'ACCOUNT_CREATE_NEW',
  ACCOUNT_APPROVE = 'ACCOUNT_APPROVE',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',
  ACCOUNT_ACCESS_SELLER_ACCOUNT_LIST = 'ACCOUNT_ACCESS_SELLER_ACCOUNT_LIST',
}

export interface UserInfo {
  id: string;
  email: string;
  name: string;
  isAdmin: boolean;
  accountId: string | null;
  provider: string;
  lastSeenAt: string | null;
  permissions?: Permission[];
  country?: string;
}

// TODO: Replace DemandSidePlatform with BuyerPlatform (revisit values)
type BaseBuyerPlatformSeat = {
  description: string;
  buyerAccountId: string;
  connectingPlatform: ConnectingPlatform;
  buyerPlatformType: BuyerPlatformType;
  buyerPlatform: BuyerPlatform;
  allowDeals: boolean;
  allowSegments: boolean;
};

export type DspBuyerPlatformSeat = BaseBuyerPlatformSeat & {
  seatCode: string;
};

export const isDspBuyerPlatformSeat = (
  seat: BuyerPlatformSeat
): seat is DspBuyerPlatformSeat => {
  return seat.buyerPlatformType === BuyerPlatformType.DSP && 'seatCode' in seat;
};

export type CuratorBuyerPlatformSeat = BaseBuyerPlatformSeat & {
  seatId: number;
};

export const isCuratorBuyerPlatformSeat = (
  seat: BuyerPlatformSeat
): seat is CuratorBuyerPlatformSeat => {
  return (
    seat.buyerPlatformType === BuyerPlatformType.CURATOR && 'seatId' in seat
  );
};

export type BuyerPlatformSeat = DspBuyerPlatformSeat | CuratorBuyerPlatformSeat;

// According to the XANDR documentation code should be string - https://docs.xandr.com/bundle/xandr-bidders/page/seat-service.html#d74987e193
export type DemandSidePlatformSeat = {
  name: string;
  code: string;
  bidderId: number;
  platform: DemandSidePlatform;
  buyerAccountId: string;
  deprecated: boolean;
};

export interface AccountInfo {
  id: string;
  isApproved: boolean;
  name: string;
  website: string;
  email: string;
  createdAt: string;
  xandrAdvertiserId?: number;
  buyerPlatformSeats?: BuyerPlatformSeat[];
  minMarginPct: number;
}

export type Segment = {
  id: number;
  name: string;
  platform: BuyerPlatform;
  activationMethod: ActivationMethod;
  isPublic: boolean;
  buyerPlatformSeats: {
    id: number;
    description: string;
    connectingPlatform: string;
    buyerPlatformType: string;
    buyerPlatform: string;
    seatId?: number;
    seatCode?: string;
    externalIdentifier: {
      seatId?: number;
      seatCode?: string;
    };
    allowDeals: boolean;
    allowSegments: boolean;
  }[];
  mediaGridSegment?: {
    id?: number;
    mediaGridId: number;
    externalId: string;
    lifetimeSeconds: number;
    dataFeeCpm: number;
    dataProviderId: number;
    source: string;
    category: string | null;
  };
  xandrSegment?: {
    id?: number;
    xandrId: number;
  };
  pubmaticSegment?: {
    pubmaticId: number;
    longName: number;
    providerAudienceId: string;
    price: number;
  };
};

export type UserAccountInfo = Pick<
  AccountInfo,
  'id' | 'name' | 'website' | 'createdAt' | 'isApproved'
>;

// NOTE: The 'value' field contains either original or translated value
// that will be used to create an audience. The 'originalValue' field
// always contains original value for comparison reason.
export type CustomAudienceQueryItem = {
  id?: string;
  value: string;
  originalValue?: string;
  type:
    | AudienceDefinitionType.TAG
    | AudienceDefinitionType.SEARCH
    | AudienceDefinitionType.EXTERNAL;
  clusterId?: number;
};

export interface CustomAudienceQueryResults {
  queries: (CustomAudienceQueryItem & { id: string | null })[];
  suggestedTags: string[];
  suggestedAudienceIds: string[];
  similarUrls: SimilarUrlsResult[];
  scale: {
    uniques: number;
    impressions: number;
  };
}

export type SimilarUrlsResult = {
  url: string;
  title: string;
  scores: Record<
    string,
    {
      score: number;
      type: AudienceDefinitionType;
    }
  >;
};

export enum AudienceScale {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type AudienceForecast = {
  scale: {
    low: number;
    medium: number;
    high: number;
  };
  urlExtensionMultiplier: number;
  audienceExtensionMultiplier: number;
};

export enum ConnectingPlatform {
  XANDR = 'XANDR',
  MEDIAGRID = 'MEDIAGRID',
}

export enum BuyerPlatformType {
  DSP = 'DSP',
  CURATOR = 'CURATOR',
}

export enum BuyerPlatform {
  XANDR = 'XANDR',
  DV360 = 'DV360',
  TTD = 'TTD',
  MEDIAMATH = 'MEDIAMATH',
  BEESWAX = 'BEESWAX',
  MEDIAGRID = 'MEDIAGRID', // NOTE: This is SSP, but used to create audiences by buyer accounts
  PUBMATIC = 'PUBMATIC',
  ADFORM = 'ADFORM',
  ZETA = 'ZETA',
  YAHOO = 'YAHOO',
  STACKADAPT = 'STACKADAPT',
}

// TODO: Replace all usages with BuyerPlatform and remove
export enum DemandSidePlatform {
  XANDR = 'XANDR',
  DV360 = 'DV360',
  TTD = 'TTD',
  MEDIAMATH = 'MEDIAMATH',
  BEESWAX = 'BEESWAX',
  MEDIAGRID = 'MEDIAGRID', // NOTE: This is SSP, but used to create audiences by buyer accounts
  ADFORM = 'ADFORM',
  ZETA = 'ZETA',
  YAHOO = 'YAHOO',
}

export interface SelectPlatformOption {
  label: string;
  value: BuyerPlatform;
  icon?: string;
}

export interface AudienceStatistic {
  day: string;
  revenue: number;
  spend: number;
  impressions: number;
}

export type OverviewFinancialAnalyticsItem = {
  spend?: number;
  revenue?: number;
  impressions: number;
};

export interface OverviewReportByDayItem
  extends OverviewFinancialAnalyticsItem {
  day: string;
}

export interface OverviewReportByPublisherItem {
  publisherName: string;
  revenue: number;
}

export interface OverviewReportBySellerItem {
  sellerName: string;
  spend: number;
}

export interface OverviewReportByBrandItem
  extends OverviewFinancialAnalyticsItem {
  brandName: string;
}

export type OverviewPossiblePeriods = 7 | 30 | 90;

export type OverviewFilterOptions = {
  reportIntervalDays: OverviewPossiblePeriods;
  publisherId?: number;
  sellerMemberId?: number;
  mediaType?: string;
};

export enum OverviewReportType {
  BY_DAY = 'BY_DAY',
  BY_PUBLISHER = 'BY_PUBLISHER',
  BY_SELLER = 'BY_SELLER',
  BY_BRAND = 'BY_BRAND',
  BY_BUYER = 'BY_BUYER',
}

export type OverviewFilterValues = {
  reportIntervalDays: number[];
  mediaTypes: string[];
  publishers?: {
    id: number;
    name: string;
  }[];
  sellers?: {
    id: number;
    name: string;
  }[];
};

export type OverviewBuyerCardsReport = {
  totalSpend: number;
  meanSpend: number;
  diffSpend: number;
  totalImpressions: number;
  meanImpressions: number;
  diffImpressions: number;
};

export enum AudienceDefinitionType {
  SEARCH = 'SEARCH',
  TAG = 'TAG',
  EXTERNAL = 'EXTERNAL',
}

export interface AudienceDefinition {
  id: string | null;
  value: string;
  type: AudienceDefinitionType;
  scale: AudienceScale;
  clusterId: number;
}

// NOTE: Why do we need the "& {}" ending? Lint is complaining
export type DefinitionItem = Omit<AudienceDefinition, 'scale'>;

export type AudienceHealthDeal = {
  dealId: number;
  activationMethod: string;
  bidRequests: number | null;
  bidsPlaced: number | null;
  bidRate: number | null;
  bidsWon: number | null;
  winRate: number | null;
  rejectCount: number | null;
  rejectRate: number | null;
  // TODO: Remove bidderId and update backend to return buyerPlatform
  bidderId: number;
  buyerSeatCode: string;
  buyerPlatform: BuyerPlatform;
};

export type AudienceHealthDealsReport = {
  audienceId: string;
  audienceName: string;
  bidRequests: number | null;
  bidsPlaced: number | null;
  bidRate: number | null;
  bidsWon: number | null;
  winRate: number | null;
  rejectCount: number | null;
  rejectRate: number | null;
  deals: AudienceHealthDeal[];
};

export type AudienceHealthCardsReport =
  | {
      bidRate: number | null;
      meanBidRate: number | null;
      diffBidRate: number | null;
      winRate: number | null;
      meanWinRate: number | null;
      diffWinRate: number | null;
      rejectRate: number | null;
      meanRejectRate: number | null;
      diffRejectRate: number | null;
    }
  | Record<string, never>;

export type AudienceHealthChartReportItem = {
  day: string;
  value: number | null;
};

export type AudienceConversionRateChartReportItem = {
  day: string;
  value: number;
};

export type AudienceHealthSpendByDealReport = {
  dealName: string;
  spend: AudienceHealthChartReportItem[];
};

export type AudienceHealthBidRequestsByDealReport = {
  dealName: string;
  bidRequests: AudienceHealthChartReportItem[];
};

export type AudienceHealthAvgBidsByDealReport = {
  dealName: string;
  avgBids: AudienceHealthChartReportItem[];
};

export type AudienceConversionRateByDealReport = {
  dealName: string;
  dailyCvrs: AudienceConversionRateChartReportItem[];
};

export type AudienceConversionRateByDealResponse = {
  dealName: string;
  dailyCvrs: {
    day: string;
    cvr: number;
    cumulativeCvr: number;
  }[];
}[];

export type AudienceHealthBlockedDomainsReport = {
  domain: string;
  predictedImpressions: number;
}[];

export type RejectionReasonsReport = {
  reason: string;
  rate: number | null;
}[];

export type TrendReport = {
  label: 'historical' | 'forecast';
  day: string;
  value: string;
  score: number;
}[];

export type DefinitionSearchTerm = {
  value: string;
  type: AudienceDefinitionType.SEARCH;
  id?: string;
};

export type DefinitionTag = {
  value: string;
  type: AudienceDefinitionType.TAG;
};

export type DefinitionExternal = {
  value: string;
  type: AudienceDefinitionType.EXTERNAL;
};

export type SelectedQueryItem =
  | DefinitionSearchTerm
  | DefinitionTag
  | DefinitionExternal;

export type DomainReport = {
  domain: string;
  [key: string]: string | number;
}[];

export type DeviceReport = {
  value: string;
  device: string;
  score: number;
}[];

export type BrowserReport = {
  value: string;
  browser: string;
  score: number;
}[];

export type GeoReport = {
  country: string;
  region: string;
  iso_3166_2: string;
  combined: number;
  [key: string]: string | number;
}[];
export type HourReport = {
  value: string;
  hour: string;
  score: number;
}[];

export type ChromeTopicReport = {
  chromeTopic: string;
  [key: string]: string | number;
}[];

export type AudiencePlanResponse = {
  id: string;
  accountId: string;
  mediaType: MediaType;
  metric: DealMetric;
  geographies: Geography[];
  viewabilityRate?: number;
  completionRate?: number;
  tags: string[];
  searchTerms: string[];
  trendReport: TrendReport | undefined;
  chromeTopicReport: ChromeTopicReport | undefined;
  domainReport: DomainReport | undefined;
  browserReport: BrowserReport | undefined;
  hourReport: HourReport | undefined;
  deviceReport: DeviceReport | undefined;
  youtubeChannelsReport: YoutubeChannelsReport | undefined;
  geoReport: GeoReport | undefined;
  graphReport: GraphReport | undefined;
  audienceName: string | null;
  createdAt: string;
  similarUrls: SimilarUrlsResult[] | undefined;
  persona?: Persona & { img: string };
  scale:
    | {
        uniques: number;
        impressions: number;
      }
    | undefined;
  suggestedAudiences: Audience[] | undefined;
  isAudienceCreated: boolean;
  plannedAudienceId?: string;
};

export type AudiencePlanDefinitionsPayload = {
  tags?: string[];
  searchTerms?: string[];
};

export type AnalyticsReportDefinitionPayload = {
  tags: string[];
  searchTerms: DefinitionItem[];
};

export type GraphReport = {
  tag: string;
  nodes: {
    value: string;
    strength: number;
    isFrom: boolean;
  }[];
}[];
