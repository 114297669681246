import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { resetUserFromSentry, setUserToSentry } from '@/utils/sentry';
import {
  AccountInfo,
  BuyerPlatform,
  BuyerPlatformType,
  isDspBuyerPlatformSeat,
  UserInfo,
} from 'shared-types';
import * as api from '@/store/api';
import {
  AddBuyerPlatformSeatInput,
  CreateAccountInput,
  Publisher,
} from '@/store/api';
import * as AccountErrors from '@/store/errors/accounts/errors';
import { auth } from '@/auth';
import { useUserInterfaceStore } from '@/store/modules/user-interface';

export const useAccountStore = defineStore('account-store', () => {
  const userInterfaceStore = useUserInterfaceStore();

  const user = ref<UserInfo | undefined>(undefined);
  const selectedAccountId = ref<string | undefined>(undefined);
  const accounts = ref(undefined);
  const account = ref<AccountInfo | undefined>(undefined);
  const publishers = ref<Publisher[] | undefined>(undefined);
  const accountUsers = ref<UserInfo[] | undefined>(undefined);

  const isUserLoaded = ref(false);
  const isAccountLoaded = ref(false);
  const isPublishersLoaded = ref(false);
  const isAccountUsersLoaded = ref(false);

  const setUser = (userInfo: UserInfo | undefined) => {
    setUserToSentry({ email: userInfo?.email || '' });
    user.value = userInfo;
    isUserLoaded.value = !!userInfo;
  };

  const setAccount = async () => {
    isAccountLoaded.value = false;
    const accountId = getSelectedAccountId();

    const loadingId = userInterfaceStore.setInitialLoading();
    const accountInfo = await api.getUserAccount(accountId);

    if (!accountInfo) {
      account.value = undefined;
    } else {
      account.value = accountInfo;
    }
    userInterfaceStore.unsetInitialLoading(loadingId);
    isAccountLoaded.value = true;
  };

  const loadPublishers = async () => {
    try {
      isPublishersLoaded.value = false;
      publishers.value = await api.getPublishers();
    } finally {
      isPublishersLoaded.value = true;
    }
  };

  const setSelectedAccountId = async (accountId: string) => {
    if (accountId === selectedAccountId.value) return;
    selectedAccountId.value = accountId;
    await setAccount();
  };

  const setAccountUsers = async () => {
    isAccountUsersLoaded.value = false;
    try {
      const accountId = getSelectedAccountId();
      accountUsers.value = await api.getUsersForAccount(accountId);
    } finally {
      isAccountUsersLoaded.value = true;
    }
  };

  const getAccountUsers = () => {
    if (!accountUsers.value) {
      throw new Error('Account users are not loaded');
    }
    return accountUsers.value.map((item) => {
      if (item.name === '' && item.email !== undefined) {
        return { ...item, name: item.email };
      }
      return item;
    });
  };

  const createAccountUser = async (input: {
    accountId: string;
    email: string;
    firstName: string;
    lastName: string;
    authenticator: string;
  }) => {
    await api.createAccountUser(input);
  };

  const createAccount = async (input: CreateAccountInput) => {
    if (!user.value) throw new Error('User is not set');
    const newAccount = await api.createAccount(input);

    user.value.accountId = newAccount.id;
    await auth.reloadUser();
  };

  const addBuyerPlatformSeat = async (
    buyerPlatformSeat: AddBuyerPlatformSeatInput
  ) => {
    if (!account.value) throw new Error('Account is not loaded');

    const accountId = getSelectedAccountId();

    const result = await api.addBuyerPlatformSeat(accountId, buyerPlatformSeat);

    if (!account.value.buyerPlatformSeats) {
      account.value.buyerPlatformSeats = [];
    }
    account.value.buyerPlatformSeats.push(result);
  };

  const removeBuyerPlatformSeatMutation = ({
    platform,
    code,
  }: {
    platform: string;
    code: string;
  }) => {
    if (!account.value) throw new Error('Account is not loaded');
    if (!account.value.buyerPlatformSeats)
      throw new Error(
        'Trying to remove DSP seat from account without buyer platform seats'
      );

    account.value.buyerPlatformSeats = account.value.buyerPlatformSeats.filter(
      (seat) =>
        !(
          isDspBuyerPlatformSeat(seat) &&
          seat.seatCode === code &&
          seat.buyerPlatform === platform
        )
    );
  };

  const removeBuyerPlatformSeat = async ({
    platform,
    platformType,
    code,
  }: {
    platform: string;
    platformType: string;
    code: string;
  }) => {
    try {
      const accountId = getSelectedAccountId();

      await api.removeBuyerPlatformSeat({
        accountId,
        platform,
        platformType,
        code,
      });
      removeBuyerPlatformSeatMutation({ platform, code });
    } catch (err) {
      if (err instanceof AccountErrors.BuyerPlatformSeatHasDealsError) {
        throw new AccountErrors.BuyerPlatformSeatHasDealsError();
      } else if (err instanceof AccountErrors.DSPSeatNotFoundError) {
        removeBuyerPlatformSeatMutation({ platform, code });
        throw new AccountErrors.DSPSeatNotFoundError();
      }
      throw err;
    }
  };

  const checkBuyerPlatformSeatStatus = async (
    platform: string,
    code: string,
    platformType: BuyerPlatformType
  ) => {
    // NOTE: It is expected that `accountId` can be `undefined` for the sign up flow
    const accountId = selectedAccountId.value;
    const seatCodeStatus = await api.checkBuyerPlatformSeatStatus({
      platform,
      code,
      accountId,
      platformType,
    });
    return seatCodeStatus;
  };

  const getBuyerDspById = (code: string, platform: BuyerPlatform) => {
    if (!account.value) throw new Error('Account is not loaded');
    if (!account.value.buyerPlatformSeats)
      throw new Error('Buyer account has no platform seats');

    // TODO: Filter by XANDR/DSP and cast to DSP buyer platform seat
    // NOTE: At the moment only Xandr platform with DSP seats is supported
    const buyerDSP = account.value.buyerPlatformSeats.find(
      (seat) =>
        isDspBuyerPlatformSeat(seat) &&
        seat.connectingPlatform === 'XANDR' &&
        seat.buyerPlatformType === 'DSP' &&
        seat.buyerPlatform === platform &&
        seat.seatCode === code
    );
    if (!buyerDSP) throw new Error('Buyer account has no dsps');

    return buyerDSP;
  };

  const getAccount = () => {
    if (!account.value) throw new Error('Account is not loaded');

    return account.value;
  };

  const getSelectedAccountId = () => {
    if (!selectedAccountId.value) throw new Error('Account id is not selected');

    return selectedAccountId.value;
  };

  const getCurrentUser = () => {
    if (!user.value) throw new Error('User is not loaded');

    return user.value;
  };

  const publisherCreationResult = ref<Omit<Publisher, 'status'> | undefined>();

  const createPublisher = async (input: {
    name: string;
    website: string;
    xandrSellerMemberId: number;
  }) => {
    const accountId = getSelectedAccountId();
    const result = await api.createPublisher({
      ...input,
      accountId,
    });

    publisherCreationResult.value = {
      accountId: result.accountId,
      name: result.name,
      website: result.website,
      apiKey: result.apiKey,
      xandrSellerMemberId: result.xandrSellerMemberId,
      publisherIds: result.publisherIds,
    };

    await loadPublishers();
  };

  const resetPublisherCreationResult = () => {
    publisherCreationResult.value = undefined;
  };

  const getPublisher = computed(() => (publisherAccountId: string) => {
    if (!publishers.value) {
      throw new Error('Publishers are not loaded');
    }

    const publisher = publishers.value.find(
      (publisher) => publisher.accountId === publisherAccountId
    );
    if (!publisher) {
      throw new Error(
        `Publisher with "${publisherAccountId}" publisher account ID has not been found`
      );
    }

    return publisher;
  });

  const unsetAccount = () => {
    resetUserFromSentry();
    user.value = undefined;
    account.value = undefined;
    accounts.value = undefined;
    selectedAccountId.value = undefined;
    isUserLoaded.value = false;
    isAccountLoaded.value = false;
  };

  return {
    setUser,
    setAccount,
    setSelectedAccountId,
    createAccountUser,
    createAccount,
    addBuyerPlatformSeat,
    removeBuyerPlatformSeat,
    checkBuyerPlatformSeatStatus,
    getBuyerDspById,
    unsetAccount,
    getAccount,
    getSelectedAccountId,
    setAccountUsers,
    getAccountUsers,
    getCurrentUser,
    loadPublishers,
    user,
    selectedAccountId,
    accounts,
    account,
    isUserLoaded,
    isAccountLoaded,
    accountUsers,
    isAccountUsersLoaded,
    publishers,
    isPublishersLoaded,
    publisherCreationResult,
    createPublisher,
    resetPublisherCreationResult,
    getPublisher,
  };
});
