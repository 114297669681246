<template>
  <div class="app">
    <scalable is-dashboard>
      <banner :is-visible="showIntegrationBanner">
        <incomplete-integration />
      </banner>
      <banner
        :is-visible="isApplicationOutOfDateBannerVisible"
        data-testid="application-out-of-date-banner"
      >
        <application-out-of-date-banner />
      </banner>
      <banner
        :is-visible="isApplicationUpToDateBannerVisible"
        is-success
        data-testid="application-up-to-date-banner"
      >
        <application-up-to-date-banner />
      </banner>
      <banner :is-visible="showUserIsOfflineBanner">
        <user-offline-banner />
      </banner>
      <banner
        :is-visible="areThirdPartyServicesDown"
        data-testid="third-party-services-are-down-banner"
      >
        <third-party-service-are-down-banner />
      </banner>

      <top-bar />
      <div class="main-container">
        <vertical-nav />
        <div class="content-wrapper">
          <router-view />
        </div>
      </div>
    </scalable>

    <template v-if="isProd">
      <small-screen-banner />
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  Ref,
  ref,
} from 'vue';
import { config, isProd } from '@/runtime-env';
import VerticalNav from '@/components/UI/Navigation/VerticalNav.vue';
import Scalable from '@/components/UI/Layout/Scalable.vue';
import SmallScreenBanner from '@/components/UI/Notifications/SmallScreenBanner.vue';
import IncompleteIntegration from '@/components/UI/IncompleteIntegration.vue';
import { useCurrentAccountInfo } from '@/composable';
import ApplicationOutOfDateBanner from '@/components/UI/Notifications/ApplicationOutOfDateBanner.vue';
import ApplicationUpToDateBanner from '@/components/UI/Notifications/ApplicationUpToDateBanner.vue';
import UserOfflineBanner from '@/components/UI/Notifications/UserOfflineBanner.vue';
import { getBackendVersionAndServicesStatus } from '@/store/api';
import { getAppVersion } from '@/utils/version';
import { logger } from '@/utils';
import { SHOW_APP_RELOAD_BANNER } from '@/const/app';
import { useRoute } from 'vue-router';
import { useAccountStore } from '@/store/modules/account/account-store';
import ThirdPartyServiceAreDownBanner from '@/components/UI/Notifications/ThirdPartyServicesAreDownBanner.vue';
import TopBar from '@/components/UI/Layout/TopBar.vue';
import Banner from '@/components/UI/Notifications/Banner.vue';
import { isAxiosError } from '@/store/api/client';

const APPLICATION_UP_TO_DATE_BANNER_VISIBILITY_TIME_MS = 3000;
enum VisibilityState {
  HIDDEN = 'hidden',
}

export default defineComponent({
  name: 'MainLayout',
  components: {
    TopBar,
    Banner,
    ThirdPartyServiceAreDownBanner,
    VerticalNav,
    SmallScreenBanner,
    Scalable,
    IncompleteIntegration,
    ApplicationOutOfDateBanner,
    ApplicationUpToDateBanner,
    UserOfflineBanner,
  },
  setup() {
    const { isAccountLoaded, buyerPlatformSeats } = useCurrentAccountInfo();
    const route = useRoute();
    const accountStore = useAccountStore();
    let appVersionCheckIntervalId: ReturnType<typeof setInterval>;
    const showApplicationOutOfDateBanner: Ref<boolean> = ref(false);
    const showApplicationUpToDateBanner: Ref<boolean> = ref(false);
    const showUserIsOfflineBanner: Ref<boolean> = ref(false);
    const areThirdPartyServicesDown: Ref<boolean> = ref(false);

    onBeforeMount(async () => {
      afterUpdateApplicationHandler();

      clearIntervalById(appVersionCheckIntervalId);
      appVersionCheckIntervalId = setInterval(
        checkAppVersionAndServicesStatus,
        config.appVersionCheckIntervalMs
      );
    });

    onBeforeUnmount(() => {
      clearIntervalById(appVersionCheckIntervalId);
    });

    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === VisibilityState.HIDDEN) {
        clearIntervalById(appVersionCheckIntervalId);
      } else {
        appVersionCheckIntervalId = setInterval(
          checkAppVersionAndServicesStatus,
          config.appVersionCheckIntervalMs
        );
      }
    });

    const afterUpdateApplicationHandler = () => {
      const showAppReloadBanner = sessionStorage.getItem(
        SHOW_APP_RELOAD_BANNER
      );

      if (showAppReloadBanner !== null) {
        sessionStorage.removeItem(SHOW_APP_RELOAD_BANNER);
        showApplicationOutOfDateBanner.value = false;
        showApplicationUpToDateBanner.value = true;

        setTimeout(() => {
          showApplicationUpToDateBanner.value = false;
        }, APPLICATION_UP_TO_DATE_BANNER_VISIBILITY_TIME_MS);
      }
    };

    const checkAppVersionAndServicesStatus = async () => {
      // NOTE: We need to make sure to wait until the user is fully authenticated (loaded)
      // before calling `status` endpoint to prevent errors triggered by the 'auth-user' decorator on the backend
      if (!accountStore.isUserLoaded) return;
      try {
        const { version: backEndVersion, servicesStatus } =
          await getBackendVersionAndServicesStatus();
        const appVersion = getAppVersion();

        showApplicationOutOfDateBanner.value = backEndVersion !== appVersion;
        areThirdPartyServicesDown.value = servicesStatus.some(
          (service) => service.isUp === false
        );
      } catch (err) {
        // NOTE: Authorization errors are handled by the Okta Axios interceptor
        // that redirects a user to the login page if an access token cannot be refreshed
        if (isAxiosError(err) && err.response?.status !== 401) {
          logger.error(err);
        }
      }
    };

    const clearIntervalById = (
      intervalId: ReturnType<typeof setInterval>
    ): void => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    const showIntegrationBanner = computed(() => {
      if (isAccountLoaded.value) {
        if (
          buyerPlatformSeats.value === null ||
          !Array.isArray(buyerPlatformSeats.value) ||
          buyerPlatformSeats.value.length === 0
        )
          return true;
      }

      return false;
    });

    const isApplicationOutOfDateBannerVisible = computed(() => {
      return (
        showApplicationOutOfDateBanner.value &&
        !route.meta.hideApplicationVersionsMismatchBanners
      );
    });

    const isApplicationUpToDateBannerVisible = computed(() => {
      return (
        !showApplicationOutOfDateBanner.value &&
        showApplicationUpToDateBanner.value &&
        !route.meta.hideApplicationVersionsMismatchBanners
      );
    });

    window.addEventListener('offline', () => {
      showUserIsOfflineBanner.value = true;
    });

    window.addEventListener('online', () => {
      showUserIsOfflineBanner.value = false;
    });

    return {
      isProd,
      showIntegrationBanner,
      isApplicationOutOfDateBannerVisible,
      isApplicationUpToDateBannerVisible,
      showUserIsOfflineBanner,
      areThirdPartyServicesDown,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define app

.app {
  min-height: 100vh;

  box-sizing: border-box;

  background-color: var(--color-app-background);
}

.main-container {
  display: flex;

  .content-wrapper {
    width: 100%;
  }
}
</style>
