import {
  AccountInfo,
  BuyerPlatform,
  BuyerPlatformType,
  DspBuyerPlatformSeat,
  UserInfo,
} from 'shared-types';
import { apiClient, isAxiosConnectionAbortedError } from '@/store/api/client';
import { AxiosConnectionAbortedError } from '@/store/errors';
import { accountsErrorsGuard } from '@/store/errors/accounts/guards';
import * as AccountsErrors from '@/store/errors/accounts/errors';

export interface CreateAccountInput {
  name: string;
  website: string;
  buyerPlatformSeats?: {
    name: string;
    code: string;
    platform: BuyerPlatform;
  }[];
  isApproved?: boolean;
  minMarginPct?: number;
}

export interface CreatedAccountResult {
  id: string;
  name: string;
  website: string;
}

export async function createAccount(
  account: CreateAccountInput
): Promise<CreatedAccountResult> {
  try {
    const { data } = await apiClient.post<{ result: CreatedAccountResult }>({
      url: '/accounts',
      data: account,
    });
    return data.result;
  } catch (err) {
    if (accountsErrorsGuard.isFailedToUpdateDealsForTaxonomyAudiences(err)) {
      throw new AccountsErrors.FailedToUpdateDealsForTaxonomyAudiences(err);
    }
    throw err;
  }
}

export interface AddBuyerPlatformSeatInput {
  buyerPlatformType: BuyerPlatformType;
  seatCode: string | number;
  description: string;
  buyerPlatform: BuyerPlatform;
}

export const addBuyerPlatformSeat = async (
  accountId: string,
  dspSeat: AddBuyerPlatformSeatInput
): Promise<DspBuyerPlatformSeat> => {
  try {
    const { data } = await apiClient.post<{ result: DspBuyerPlatformSeat }>({
      url: `/accounts/buyer-platform-seat`,
      data: {
        accountId,
        platform: dspSeat.buyerPlatform,
        code: dspSeat.seatCode,
        platformType: dspSeat.buyerPlatformType,
        description: dspSeat.description,
      },
      options: {
        timeout: 90000,
      },
    });
    return data.result;
  } catch (err) {
    if (accountsErrorsGuard.isFailedToPersistBuyerPlatformSeatError(err)) {
      throw new AccountsErrors.FailedToPersistBuyerPlatformSeatError(err);
    } else if (
      accountsErrorsGuard.isFailedToCreateBuyerPlatformSeatError(err)
    ) {
      throw new AccountsErrors.FailedToCreateBuyerPlatformSeatError(err);
    } else if (
      accountsErrorsGuard.isFailedToUpdateDealsForTaxonomyAudiences(err)
    ) {
      throw new AccountsErrors.FailedToUpdateDealsForTaxonomyAudiences(err);
    } else if (accountsErrorsGuard.isBuyerPlatformSeatAlreadyExistsError(err)) {
      throw new AccountsErrors.BuyerPlatformSeatAlreadyExistsError(err);
    } else if (isAxiosConnectionAbortedError(err)) {
      throw new AxiosConnectionAbortedError();
    }
    throw err;
  }
};

export type RemoveBuyerPlatformSeatInput = {
  code: string;
  platform: string;
  platformType: string;
  accountId: string;
};

export const removeBuyerPlatformSeat = async ({
  code,
  platform,
  platformType,
  accountId,
}: RemoveBuyerPlatformSeatInput): Promise<void> => {
  try {
    await apiClient.delete({
      url: `/accounts/buyer-platform-seat`,
      data: {
        accountId,
        platform,
        platformType,
        code,
      },
    });
  } catch (err) {
    if (accountsErrorsGuard.isBuyerPlatformSeatHasDealsError(err)) {
      throw new AccountsErrors.BuyerPlatformSeatHasDealsError(err);
    } else if (accountsErrorsGuard.isDSPSeatNotFoundError(err)) {
      throw new AccountsErrors.DSPSeatNotFoundError(err);
    } else if (
      accountsErrorsGuard.isBuyerPlatformSeatAccountMismatchError(err)
    ) {
      throw new AccountsErrors.BuyerPlatformSeatAccountMismatchError(err);
    }
    throw err;
  }
};

export const checkBuyerPlatformSeatStatus = async ({
  platform,
  code,
  accountId,
  platformType,
}: {
  platform: string;
  code: string;
  accountId: string | undefined;
  platformType: BuyerPlatformType;
}): Promise<{ exists: boolean; allowed: boolean }> => {
  // NOTE: `accountId` can be undefined for the create account scenario
  let url = `/accounts/buyer-platform-seat?&platform=${platform}&code=${code}&platformType=${platformType}`;
  if (accountId !== undefined) {
    url = `${url}&accountId=${accountId}`;
  }
  const { data } = await apiClient.get<{ exists: boolean; allowed: boolean }>({
    url,
  });
  return data;
};

export interface CreateAccountUserInput {
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  authenticator: string;
}

export async function createAccountUser(
  input: CreateAccountUserInput
): Promise<void> {
  try {
    await apiClient.post({
      url: '/accounts/users',
      data: input,
    });
  } catch (err) {
    if (accountsErrorsGuard.isEmailAlreadyExistsError(err)) {
      throw new AccountsErrors.EmailAlreadyExistsError(err);
    } else if (accountsErrorsGuard.isEmailValidationFailedError(err)) {
      throw new AccountsErrors.EmailValidationFailedError(err);
    } else if (accountsErrorsGuard.isPasswordValidationFailedError(err)) {
      throw new AccountsErrors.PasswordValidationFailedError(err);
    } else if (accountsErrorsGuard.isUserCreationError(err)) {
      throw new AccountsErrors.UserCreationError(err);
    }
    throw err;
  }
}

export async function getUserAccount(accountId: string): Promise<AccountInfo> {
  try {
    const { data } = await apiClient.get({
      url: `/signin?accountId=${accountId}`,
      retryOnFail: true,
    });
    const { buyerAccount, sellerAccount, ...rest } = data.result;

    return {
      ...rest,
      buyer: buyerAccount,
      seller: sellerAccount,
    };
  } catch (err) {
    if (accountsErrorsGuard.isAccountNotFoundError(err)) {
      throw new AccountsErrors.AccountNotFoundError(err);
    }
    throw err;
  }
}

export async function getAccounts(): Promise<AccountInfo[]> {
  try {
    const { data } = await apiClient.get<{ result: AccountInfo[] }>({
      url: '/accounts',
      retryOnFail: true,
    });
    return data.result;
  } catch (err) {
    if (accountsErrorsGuard.isUserNotAdminError(err)) {
      throw new AccountsErrors.UserNotAdminError(err);
    }
    throw err;
  }
}

export const removeAccount = async ({
  accountId,
  deletedAccountId,
}: {
  accountId: string;
  deletedAccountId: string;
}): Promise<void> => {
  try {
    await apiClient.delete({
      url: `/accounts`,
      data: {
        deletedAccountId,
        accountId,
      },
    });
  } catch (err) {
    if (accountsErrorsGuard.isBuyerAccountHasAudiencesError(err)) {
      throw new AccountsErrors.BuyerAccountHasAudiencesError(err);
    } else if (accountsErrorsGuard.isDeleteNotAllowedError(err)) {
      throw new AccountsErrors.DeleteNotAllowedError(err);
    } else if (accountsErrorsGuard.isUserNotAdminError(err)) {
      throw new AccountsErrors.UserNotAdminError(err);
    }
    throw err;
  }
};

export const approveAccount = async (
  accountId: string,
  minMarginPct?: number
): Promise<void> => {
  try {
    await apiClient.patch({
      url: '/accounts/approve-account',
      data: {
        accountId: accountId,
        minMarginPct,
      },
    });
  } catch (err) {
    if (accountsErrorsGuard.isTaxonomyDealsCreationError(err)) {
      throw new AccountsErrors.TaxonomyDealsCreationError(err);
    }
    throw err;
  }
};

export async function getUsersForAccount(
  accountId: string
): Promise<UserInfo[]> {
  const { data } = await apiClient.get<{ result: UserInfo[] }>({
    url: `/accounts/users?accountId=${accountId}`,
  });
  return data.result;
}

export const updateAccountMargin = async ({
  accountId,
  minMarginPct,
}: {
  accountId: string;
  minMarginPct: number;
}) => {
  try {
    return await apiClient.patch({
      url: `/accounts/update-margin`,
      data: {
        accountId,
        minMarginPct,
      },
    });
  } catch (err) {
    if (accountsErrorsGuard.isUserNotAdminError(err)) {
      throw new AccountsErrors.UserNotAdminError(err);
    }
    throw err;
  }
};
