export enum AuthApiErrorMessage {
  NOT_LOGGED_IN = 'Not logged in.',
}

export enum AuthErrorCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  INVALID_EMAIL = 'INVALID_EMAIL',
  USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN',
  TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export class AuthError extends Error {
  code: string;
  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

export class AuthEmailNotConfirmedError extends AuthError {
  constructor() {
    super('Email not confirmed.', AuthErrorCode.EMAIL_NOT_CONFIRMED);
  }
}

export class AuthInvalidCredentialsError extends AuthError {
  constructor() {
    super('Invalid email or password.', AuthErrorCode.INVALID_CREDENTIALS);
  }
}

export class AuthUserAlreadyRegisteredError extends AuthError {
  constructor() {
    super('User already registered.', AuthErrorCode.USER_ALREADY_REGISTERED);
  }
}

export class AuthInvalidEmailError extends AuthError {
  constructor() {
    super('Invalid email.', AuthErrorCode.INVALID_EMAIL);
  }
}

export class AuthUserNotLoggedIn extends AuthError {
  constructor() {
    super('Not logged in.', AuthErrorCode.USER_NOT_LOGGED_IN);
  }
}

export class AuthTokenRefreshFailed extends AuthError {
  constructor() {
    super('Token refresh failed.', AuthErrorCode.TOKEN_REFRESH_FAILED);
  }
}

export class AuthNetworkError extends AuthError {
  constructor() {
    super('Network error.', AuthErrorCode.NETWORK_ERROR);
  }
}

export class AuthTokenExpired extends AuthError {
  constructor() {
    super('Token expired.', AuthErrorCode.TOKEN_EXPIRED);
  }
}
