export abstract class ClientError extends Error {
  public code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export enum ClientErrorCode {
  BUYER_PLATFORM_SEAT_HAS_DEALS = 'BUYER_PLATFORM_SEAT_HAS_DEALS',
  BUYER_PLATFORM_SEAT_NOT_FOUND = 'BUYER_PLATFORM_SEAT_NOT_FOUND',
  DSP_SEAT_NOT_ALLOWED = 'DSP_SEAT_NOT_ALLOWED',
  SSP_SEAT_NOT_FOUND = 'SSP_SEAT_NOT_FOUND',
  AXIOS_CONNECTION_ABORTED_ERROR = 'ECONNABORTED',
  REQUEST_ABORTED = 'ERR_CANCELED',
}

export class BuyerPlatformSeatHasDealsError extends ClientError {
  constructor(
    message = 'Cannot delete DSP seat as it has associated deals. Please contact support if you like to remove it.'
  ) {
    super(ClientErrorCode.BUYER_PLATFORM_SEAT_HAS_DEALS, message);
  }
}

export class DSPSeatNotFoundError extends ClientError {
  constructor(message = 'DSP seat not found.') {
    super(ClientErrorCode.BUYER_PLATFORM_SEAT_NOT_FOUND, message);
  }
}

export class DSPSeatNotAllowedError extends ClientError {
  constructor(message = 'DSP seat is not allowed.') {
    super(ClientErrorCode.DSP_SEAT_NOT_ALLOWED, message);
  }
}

export class SSPSeatNotFoundError extends ClientError {
  constructor(message = 'SSP seat not found.') {
    super(ClientErrorCode.SSP_SEAT_NOT_FOUND, message);
  }
}

export class AxiosConnectionAbortedError extends ClientError {
  constructor() {
    super(
      ClientErrorCode.AXIOS_CONNECTION_ABORTED_ERROR,
      'Your request cannot be processed in time by the remote server. Please try again later.'
    );
  }
}

export class RequestAborted extends ClientError {
  constructor() {
    super(ClientErrorCode.REQUEST_ABORTED, 'Request aborted by the client.');
  }
}
