<script lang="ts">
export enum SearchModalEvents {
  CLOSE = 'close',
}
</script>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { SearchInput } from '@airgrid/components';
import { useAudienceStore } from '@/store/modules/audiences/audience-store';
import { useAccountStore } from '@/store/modules/account/account-store';
import { routes } from '@/router/routes';
import { BaseSpinner } from '@airgrid/components';
import { useMeasureStore } from '@/store/modules/measure/measure-store';
import SearchModalResultsGroup, {
  SearchModalResultsGroupEvents,
} from '@/components/UI/Layout/SearchModal/SearchModalResultsGroup.vue';
import { router } from '@/router/router';
import { doesSearchMatch } from '@/utils';

const props = defineProps<{
  show: boolean;
}>();
const emit = defineEmits([SearchModalEvents.CLOSE]);
const searchString = ref('');
const audienceStore = useAudienceStore();
const accountStore = useAccountStore();
const measureStore = useMeasureStore();

const pages = [
  {
    name: 'Attribution & Reporting API',
    route: routes.measure.attributionPixelGroups.list,
    prefix: 'Labs',
  },
  {
    name: 'Demographic Audiences',
    route: routes.labs.demographicAudiencesList,
    prefix: 'Labs',
  },
  {
    name: 'Personas',
    route: routes.createAudience,
    prefix: 'Labs',
  },
  {
    name: 'Brand Personas',
    route: routes.labs.brandPersonas,
    prefix: 'Labs',
  },
  {
    name: 'Synthetic Panel',
    route: routes.labs.demographicAudiencesList,
    prefix: 'Labs',
  },
];

const accountUsers = computed(() => accountStore.getAccountUsers());

const isLoaded = computed(
  () =>
    measureStore.isAttributionPixelGroupsLoaded &&
    audienceStore.isAudiencesLoaded
);

const audiencesFound = computed(() => {
  if (searchString.value.length > 0) {
    return audienceStore
      .getAudiences()
      .filter((audience) => doesSearchMatch(audience.name, searchString.value))
      .map((audience) => ({
        ...audience,
        name: audience.name,
        route: {
          name: routes.audiences.details.name,
          params: { id: audience.id },
        },
        owner: accountUsers.value.find((user) => user.id === audience.userId)
          ?.email,
      }));
  } else {
    return [];
  }
});

const pagesFound = computed(() => {
  if (searchString.value.length === 0) {
    return [];
  } else {
    return pages.filter((page) =>
      doesSearchMatch(page.name, searchString.value)
    );
  }
});

const pixelsFound = computed(() => {
  if (searchString.value.length === 0 || !measureStore.attributionPixelGroups) {
    return [];
  } else {
    return measureStore.attributionPixelGroups
      .filter((pixel) => doesSearchMatch(pixel.name, searchString.value))
      .map((item) => ({
        ...item,
        route: {
          name: routes.measure.attributionPixelGroups.pixelGroup.name,
          params: { id: item.id },
        },
      }));
  }
});

const isEmpty = computed(
  () =>
    audiencesFound.value.length === 0 &&
    pagesFound.value.length === 0 &&
    pixelsFound.value.length === 0
);

const onClose = () => {
  resetHoveredItemAndGroup();
  setSearchString('');
  emit(SearchModalEvents.CLOSE);
};

const setSearchString = (value: string) => {
  resetHoveredItemAndGroup();
  searchString.value = value;
};

onBeforeMount(async () => {
  await accountStore.setAccountUsers();

  await measureStore.loadAttributionPixelGroups();
});

const hoveredGroup = ref<undefined | number>();
const hoveredItem = ref<undefined | number>();

const resetHoveredItemAndGroup = () => {
  hoveredGroup.value = undefined;
  hoveredItem.value = undefined;
};

const groups = computed(() => [
  { items: audiencesFound.value, title: 'Audiences' },
  { items: pagesFound.value, title: 'Pages' },
  { items: pixelsFound.value, title: 'Measure' },
]);

const groupLengths = computed(() =>
  groups.value.map((group) => group.items.length)
);

/**
 * This method checks for the next non-empty group in the specified direction.
 * It is used to handle cases where the current group has no items, such as when
 * navigating away from the last item of group_1 to group_2, which may be empty.
 * The method returns the index of the next non-empty group. If no such group exists,
 * it returns -1.
 *
 * @param {boolean} isForward - Direction of navigation. `true` for forward, `false` for backward.
 * @param {number} currentGroupIndex - The index of the current group.
 * @returns {number} - The index of the next non-empty group or -1 if no non-empty group is found.
 */
const getNextNotEmptyGroupIndex = (
  isForward: boolean,
  currentGroupIndex: number
): number => {
  const delta = isForward ? 1 : -1;
  let index = currentGroupIndex + delta;

  while (index >= 0 && index < groups.value.length) {
    const isNextGroupNotEmpty = groups.value[index].items.length;
    if (isNextGroupNotEmpty) {
      return index;
    }
    index += delta;
  }

  return -1;
};

/**
 * Handles the navigation through groups and items when the arrow keys are pressed.
 * - If no item or group is currently hovered, it sets the first non-empty group and the first item in it as hovered.
 * - If an item and group are already hovered, it determines the next or previous non-empty group and sets the hover accordingly.
 * - Adjusts the hovered item and group based on the boundary conditions and ensures that the navigation skips empty groups.
 *
 * @param {boolean} isArrowDown - Direction of navigation. `true` for down (next item), `false` for up (previous item).
 */
const setHoveredGroupAndArrowButtonPress = (isArrowDown: boolean) => {
  // If no item or group is currently hovered, set the first non-empty group and the first item in it as hovered
  if (hoveredItem.value === undefined || hoveredGroup.value === undefined) {
    hoveredGroup.value = groupLengths.value.findIndex((group) => group > 0);
    hoveredItem.value = 0;
  } else {
    // Determine the next or previous non-empty group index
    const nextNotEmptyGroupIndex = getNextNotEmptyGroupIndex(
      isArrowDown,
      hoveredGroup.value
    );

    const currentGroupItems = groupLengths.value[hoveredGroup.value];
    const isAtBoundary =
      (isArrowDown && hoveredItem.value === currentGroupItems - 1) ||
      (!isArrowDown && hoveredItem.value === 0);

    // If there's no next non-empty group and the current item is at the boundary, do nothing
    if (nextNotEmptyGroupIndex === -1 && isAtBoundary) {
      return;
    }

    // Adjust the hovered item and group based on the navigation direction and boundary conditions
    if (isArrowDown) {
      if (isAtBoundary) {
        hoveredGroup.value = nextNotEmptyGroupIndex;
        hoveredItem.value = 0;
      } else {
        hoveredItem.value++;
      }
    } else {
      if (isAtBoundary) {
        hoveredGroup.value = nextNotEmptyGroupIndex;
        hoveredItem.value = groupLengths.value[hoveredGroup.value] - 1;
      } else {
        hoveredItem.value--;
      }
    }
  }
};

const handleArrowUpAndDownPress = (event: KeyboardEvent) => {
  if (
    !props.show ||
    searchString.value === '' ||
    Math.max(...groupLengths.value) === 0
  ) {
    return;
  }
  const isArrowUp = event.key === 'ArrowUp';
  const isArrowDown = event.key === 'ArrowDown';
  if (isArrowUp || isArrowDown) {
    event.preventDefault();
    event.stopPropagation();

    setHoveredGroupAndArrowButtonPress(isArrowDown);
  } else if (event.key === 'Enter') {
    openHoveredItem();
  }
};

const openHoveredItem = () => {
  if (hoveredItem.value !== undefined && hoveredGroup.value !== undefined) {
    const hoveredGroupItems = groups.value[hoveredGroup.value].items;
    const hoveredItemRoute = hoveredGroupItems[hoveredItem.value].route;
    router.push(hoveredItemRoute);
    onClose();
  }
};

const updateHoveredItem = (payload: {
  hoveredItem: number;
  hoveredGroup: number;
}) => {
  hoveredItem.value = payload.hoveredItem;
  hoveredGroup.value = payload.hoveredGroup;
};

onMounted(() => {
  document.addEventListener('keydown', handleArrowUpAndDownPress);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleArrowUpAndDownPress);
});
</script>

<template>
  <teleport to="#app">
    <transition name="fade">
      <div
        class="search-modal"
        v-if="show"
        @keydown.esc="onClose"
        data-testid="search-modal"
      >
        <div class="search-modal__body">
          <search-input
            focus-on-mounted
            class="search-modal__input"
            placeholder="Search (/) for audiences, pixels, pages and more"
            @search="setSearchString"
            @clear-search="() => setSearchString('')"
            :value="searchString"
            :disabled="!isLoaded"
          >
            <template #fieldSuffix>
              <div class="search-modal__esc-btn" @click="onClose">ESC</div>
            </template>
          </search-input>

          <div
            class="search-modal__content"
            :class="{
              'search-modal__content--empty':
                isEmpty || searchString.length === 0,
            }"
          >
            <template v-if="!isLoaded"> <base-spinner /> </template>
            <template v-else-if="searchString.length === 0">
              Search for audiences, pixels or pages
            </template>
            <template v-else-if="isEmpty">No items found</template>
            <template v-else>
              <search-modal-results-group
                v-for="(group, idx) in groups"
                :key="idx"
                :title="group.title"
                :search-string="searchString"
                :items="group.items"
                @[SearchModalResultsGroupEvents.CLOSE]="onClose"
                @[SearchModalResultsGroupEvents.UPDATE_HOVERED_ITEM]="
                  updateHoveredItem
                "
                :group-index="idx"
                :hovered-item="hoveredItem"
                :hovered-group="hoveredGroup"
              >
                <template
                  v-if="group.title === 'Audiences'"
                  #item-right-section="{ item }"
                >
                  <template v-if="item.owner">
                    Owner:
                    <div class="search-modal-results-group-item-label">
                      {{ item.owner }}
                    </div>
                  </template>
                </template>
              </search-modal-results-group>
            </template>
          </div>
        </div>
        <div class="search-modal__shadow" @click.prevent="onClose" />
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss" scoped>
// @define search-modal

@import '@airgrid/components/styles/shared/typography';
@import '@/styles/animations';

.search-modal {
  top: 0;
  left: 0;
  z-index: var(--z-modal);
  position: fixed;
  width: 100vw;
  height: 100vh;
  cursor: auto;

  &__shadow {
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--z-modal) - 1);
    width: 100vw;
    height: 100%;
    background: rgba(227, 227, 228, 0.75);
    backdrop-filter: blur(5px);
  }

  &__body {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--color-blacks-white);
    min-width: 782px;
    max-width: 100vw;
    min-height: 326px;
    max-height: 70%;
    overflow-y: auto;
    z-index: var(--z-modal);

    display: flex;
    flex-direction: column;

    padding: 24px;
    border-radius: var(--border-radius-block);

    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  }

  &__esc-btn {
    position: absolute;
    top: 9px;
    right: 12px;

    display: flex;
    padding: 3px 8px;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: var(--border-radius-block);
    border: 1px solid var(--color-borders-input-default);

    color: var(--color-blacks-grey);
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;

    cursor: pointer;
  }

  &__input {
    margin-bottom: 32px;
    flex-grow: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @extend %generic-text-styles;
    color: var(--color-blacks-lighter-grey);
    font-size: 12px;
    line-height: 16px;

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

:deep(.search .input-field__input) {
  height: 40px;
}

:deep(.base-spinner) {
  margin: auto;
}
</style>
