import { UserAccountInfo, UserInfo } from 'shared-types';
import { computed, ComputedRef } from 'vue';
import { useAdminStore } from '@/store/modules/admin/admin-store';

export const useCurrentAdminInfo = (): {
  isAdminAccountsLoaded: ComputedRef<boolean>;
  adminAccounts: ComputedRef<UserAccountInfo[] | undefined>;
  adminAccountUsers: ComputedRef<UserInfo[] | undefined>;
} => {
  const adminStore = useAdminStore();

  const isAdminAccountsLoaded = computed(
    () => adminStore.isAdminAccountsLoaded
  );

  const getComputedIfLoaded = <T>(
    toCompute: () => T
  ): ComputedRef<T | undefined> =>
    computed(() => {
      if (!isAdminAccountsLoaded.value) {
        return undefined;
      }
      return toCompute();
    });

  const adminAccounts = getComputedIfLoaded(() => adminStore.getAdminAccounts);

  const adminAccountUsers = getComputedIfLoaded(
    () => adminStore.getAdminAccountUsers
  );

  return {
    isAdminAccountsLoaded,
    adminAccounts,
    adminAccountUsers,
  };
};
