import { apiClient } from '@/store/api/client';
import * as PublishersErrors from '@/store/errors/publishers/errors';
import { publishersErrorsGuard } from '@/store/errors/publishers/guards';

export enum PublisherStatus {
  LIVE = 'LIVE',
  CREATED = 'CREATED',
}

export type Publisher = {
  accountId: string;
  name: string;
  website: string;
  apiKey: string;
  publisherIds: string[];
  xandrSellerMemberId: number;
  status: PublisherStatus;
};

export async function getPublishers(): Promise<Publisher[]> {
  const { data } = await apiClient.get({
    url: '/publishers',
    retryOnFail: true,
  });

  return data;
}

export type CreatePublisherInput = {
  accountId: string;
  name: string;
  website: string;
  xandrSellerMemberId: number;
};

export type CreatePublisherResponse = {
  accountId: string;
  name: string;
  website: string;
  xandrSellerMemberId: number;
  apiKey: string;
  publisherIds: string[];
};

export const createPublisher = async (
  input: CreatePublisherInput
): Promise<CreatePublisherResponse> => {
  try {
    const { data } = await apiClient.post<{
      result: CreatePublisherResponse;
    }>({
      url: `/publishers`,
      data: input,
    });

    return data.result;
  } catch (err) {
    if (publishersErrorsGuard.isPublisherAlreadyExistError(err)) {
      throw new PublishersErrors.PublisherAlreadyExistError(err);
    } else if (
      publishersErrorsGuard.isPublisherOnboardingNotAllowedError(err)
    ) {
      throw new PublishersErrors.PublisherOnboardingIsNotAllowedError();
    }
    throw err;
  }
};
