import { Permission, UserInfo } from 'shared-types';
import { computed, ComputedRef } from 'vue';
import { SignInProvider } from '@/auth';
import { useAccountStore } from '@/store/modules/account/account-store';

export const useCurrentUserInfo = (): {
  user: ComputedRef<UserInfo | undefined>;
  email: ComputedRef<string | undefined>;
  isUserLoaded: ComputedRef<boolean>;
  isAdmin: ComputedRef<boolean | undefined>;
  accountId: ComputedRef<string | undefined | null>;
  hasAdminPermissions: ComputedRef<boolean | undefined>;
  permissions: ComputedRef<Permission[] | undefined>;
  name: ComputedRef<string | undefined>;
  isGoogleProvider: ComputedRef<boolean>;
} => {
  const accountStore = useAccountStore();

  const isUserLoaded = computed(() => accountStore.isUserLoaded);
  const user = computed(() => accountStore.user);
  const email = computed(() => user.value?.email);
  const isAdmin = computed(() => user.value?.isAdmin);
  const accountId = computed(() => user.value?.accountId);
  const hasAdminPermissions = computed(() =>
    [Permission.ACCOUNT_ACCESS_ALL, Permission.ACCOUNT_CREATE_NEW].some(
      (permission) => user.value?.permissions?.includes(permission)
    )
  );
  const permissions = computed(() => user.value?.permissions);
  const name = computed(() => user.value?.name);
  const isGoogleProvider = computed(
    () => user.value?.provider === SignInProvider.GOOGLE
  );

  return {
    user,
    email,
    isUserLoaded,
    isAdmin,
    accountId,
    hasAdminPermissions,
    permissions,
    name,
    isGoogleProvider,
  };
};
