import { useAuthStore } from '@/store/modules/auth';

export const useSignOut = (): {
  signOut: () => void;
} => {
  const authStore = useAuthStore();

  const signOut = async () => {
    // NOTE: We do not need to explicitly re-route a user to the Login page because
    // this method is eventually calling Okta sign out method that opens an Okta callback URL
    // and then redirects a user to the Login page.
    await authStore.signOut();
  };

  return {
    signOut,
  };
};
