import { ObjectDirective } from 'vue';
import throttle from 'lodash/throttle';

// this value is wrapper padding that prevents content shadowing
const WRAPPER_PADDING_TOP = 15;
const WRAPPER_PADDING_BOTTOM = 15;

function scrollStyling(e: Element | Event) {
  const target: HTMLElement = (e as Event).target
    ? ((e as Event).target as HTMLElement)
    : (e as HTMLElement);
  const parentNode = target.parentNode as HTMLElement;
  const isBottomShadowNeeded =
    target.scrollTop >=
    target.scrollHeight - target.offsetHeight - WRAPPER_PADDING_BOTTOM;
  const isTopShadowNeeded = target.scrollTop > WRAPPER_PADDING_TOP;

  if (parentNode) {
    parentNode.classList.add('app-scroll-shadow-vertical');
    isBottomShadowNeeded
      ? parentNode.classList.remove('app-scroll-shadow-vertical--bottom')
      : parentNode.classList.add('app-scroll-shadow-vertical--bottom');
    isTopShadowNeeded
      ? parentNode.classList.add('app-scroll-shadow-vertical--top')
      : parentNode.classList.remove('app-scroll-shadow-vertical--top');
  }
}

export const verticalScrollShadow: ObjectDirective<
  HTMLElement,
  HTMLElement
> = (() => {
  let scrollHandler: EventListener;
  let resizeHandler: EventListener;

  return {
    created(el: HTMLElement): void {
      scrollHandler = throttle(scrollStyling, 200);
      resizeHandler = throttle(scrollStyling.bind(null, el), 200);

      el.addEventListener('scroll', scrollHandler);
      window.addEventListener('resize', resizeHandler);
    },
    mounted(el: HTMLElement): void {
      scrollStyling(el);
    },
    unmounted(el: HTMLElement): void {
      el.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', resizeHandler);
    },
  };
})();
