import packageJson from '../../package.json';
import { logger } from './logger';
import { getBackendVersionAndServicesStatus } from '@/store/api';

export const getAppVersion = (): string => {
  return packageJson.version;
};

export const printVersionAndServicesStatus = async (): Promise<void> => {
  try {
    const { version, servicesStatus } =
      await getBackendVersionAndServicesStatus();

    const status = servicesStatus
      .map(
        (service) => `${service.name}: ${service.isUp ? 'Healthy' : 'Degraded'}`
      )
      .join('\n    ');

    logger.info(`AirGrid Studio
    web-app:     ${getAppVersion()}
    backend-api: ${version}
    
    ${status} `);
  } catch (err) {
    logger.error(err);
  }
};
