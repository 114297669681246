<template>
  <div
    class="initial-loading-overlay"
    :class="{ 'initial-loading-overlay--auth': isAuthLayout }"
  >
    <div class="initial-loading-overlay__content">
      <base-spinner class="initial-loading-overlay__spinner" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { BaseSpinner } from '@airgrid/components';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'InitialLoadingOverlay',
  components: {
    BaseSpinner,
  },
  setup() {
    const route = useRoute();
    const isAuthLayout = computed(
      () => route.meta.layout === 'AuthLayout' || !route.meta.layout
    );
    return {
      isAuthLayout,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define initial-loading-overlay

.initial-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__spinner {
    margin-top: -100px;
  }
}
</style>
