<template>
  <service-unavailable-icon />
  <span>
    <strong>A service we rely on is currently unstable.</strong> You can
    continue to use the application, but please be aware you may experience
    degraded performance
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ServiceUnavailableIcon from '@/assets/icons/service-unavailable.svg';

export default defineComponent({
  name: 'ThirdPartyServiceAreDownBanner',
  components: {
    ServiceUnavailableIcon,
  },
});
</script>
