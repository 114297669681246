import { BackendError, isBackendError } from '@/store/errors';
import { PublishersErrorCodes } from '@/store/errors/publishers/errors';

export const isPublisherAlreadyExistError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === PublishersErrorCodes.PUBLISHER_ALREADY_EXIST
  );
};

export const isPublisherOnboardingNotAllowedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      PublishersErrorCodes.PUBLISHER_ONBOARDING_IS_NOT_ALLOWED
  );
};

export const publishersErrorsGuard = Object.freeze({
  isPublisherAlreadyExistError,
  isPublisherOnboardingNotAllowedError,
});
