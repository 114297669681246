import { BackendError, ClientError } from '@/store/errors';

export enum AccountsErrorCodes {
  FAILED_CREATE_BUYER_PLATFORM_SEAT = 'FAILED_CREATE_BUYER_PLATFORM_SEAT',
  FAILED_PERSIST_BUYER_PLATFORM_SEAT = 'FAILED_PERSIST_BUYER_PLATFORM_SEAT',
  FAILED_UPDATED_DEALS_TAXONOMY = 'FAILED_UPDATED_DEALS_TAXONOMY',
  FAILED_CREATE_SSP_SEAT = 'FAILED_CREATE_SSP_SEAT',
  FAILED_PERSIST_SSP_SEAT = 'FAILED_PERSIST_SSP_SEAT',
  MEMBER_INVITE_NOT_FOUND = 'MEMBER_INVITE_NOT_FOUND',
  MEMBER_INVITE_NOT_CREATED = 'MEMBER_INVITE_NOT_CREATED',
  MEMBER_INVITE_ACCOUNT_MISMATCH = 'MEMBER_INVITE_ACCOUNT_MISMATCH',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  NO_ACCOUNT_FOUND = 'NO_ACCOUNT_FOUND',
  MEMBER_HAS_ACCEPTED_INVITE = 'MEMBER_HAS_ACCEPTED_INVITE',
  USER_NOT_ADMIN = 'USER_NOT_ADMIN',
  ACCOUNT_HAS_ACTIVE_AUDIENCE = 'ACCOUNT_HAS_ACTIVE_AUDIENCE',
  DELETE_NOT_ALLOWED = 'DELETE_NOT_ALLOWED',
  BUYER_PLATFORM_SEAT_HAS_DEALS = 'BUYER_PLATFORM_SEAT_HAS_DEALS',
  BUYER_PLATFORM_SEAT_NOT_FOUND = 'BUYER_PLATFORM_SEAT_NOT_FOUND',
  BUYER_PLATFORM_SEAT_ACCOUNT_MISMATCH = 'BUYER_PLATFORM_SEAT_ACCOUNT_MISMATCH',
  SSP_SEAT_NOT_FOUND = 'SSP_SEAT_NOT_FOUND',
  SSP_SEAT_ACCOUNT_MISMATCH = 'SSP_SEAT_ACCOUNT_MISMATCH',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  USER_ALREADY_HAS_PROFILE = 'USER_ALREADY_HAS_PROFILE',
  BUYER_PLATFORM_SEAT_ALREADY_EXIST_ERROR = 'BUYER_PLATFORM_SEAT_ALREADY_EXIST_ERROR',
  TAXONOMY_DEALS_CREATION_ERROR = 'TAXONOMY_DEALS_CREATION_ERROR',
  EMAIL_VALIDATION_FAILED = 'EMAIL_VALIDATION_FAILED',
  PASSWORD_VALIDATION_FAILED = 'PASSWORD_VALIDATION_FAILED',
  USER_CREATION_FAILED = 'USER_CREATION_FAILED',
}

export class EmailAlreadyExistsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.EMAIL_ALREADY_EXISTS,
      err ? err.response.data.message : 'Email already exists in our system'
    );
  }
}

export class EmailValidationFailedError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.EMAIL_VALIDATION_FAILED,
      err ? err.response.data.message : 'Please try another email'
    );
  }
}

export class PasswordValidationFailedError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.PASSWORD_VALIDATION_FAILED,
      err ? err.response.data.message : 'Please try another password'
    );
  }
}

export class UserCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.USER_CREATION_FAILED,
      err
        ? err.response.data.message
        : 'User cannot be created using provided input'
    );
  }
}

export class FailedToCreateBuyerPlatformSeatError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.FAILED_CREATE_BUYER_PLATFORM_SEAT,
      err ? err.response.data.message : 'Failed to create DSP seat entity.'
    );
  }
}

export class FailedToPersistBuyerPlatformSeatError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.FAILED_PERSIST_BUYER_PLATFORM_SEAT,
      err ? err.response.data.message : 'Failed to persist DSP seat entity.'
    );
  }
}

export class FailedToUpdateDealsForTaxonomyAudiences extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.FAILED_UPDATED_DEALS_TAXONOMY,
      err
        ? err.response.data.message
        : 'Failed to update deals for taxonomy audiences.'
    );
  }
}

export class FailedToCreateSSPSeatError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.FAILED_CREATE_SSP_SEAT,
      err ? err.response.data.message : 'Failed to create SSP seat entity.'
    );
  }
}

export class FailedToPersistSSPSeatError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.FAILED_PERSIST_SSP_SEAT,
      err ? err.response.data.message : 'Failed to persist SSP seat entity.'
    );
  }
}

export class MemberInviteNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.MEMBER_INVITE_NOT_FOUND,
      err ? err.response.data.message : `Couldn't find a Member Invite.`
    );
  }
}

export class MemberInviteNotCreatedError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.MEMBER_INVITE_NOT_CREATED,
      err ? err.response.data.message : 'Failed to create Member Invite.'
    );
  }
}

export class MemberInviteAccountMismatchError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.MEMBER_INVITE_ACCOUNT_MISMATCH,
      err
        ? err.response.data.message
        : `Provided accountId and existing Member Invite accountId does not match.`
    );
  }
}

export class UserNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.USER_NOT_FOUND,
      err ? err.response.data.message : `Couldn't find a User.`
    );
  }
}

export class AccountNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.NO_ACCOUNT_FOUND,
      err ? err.response.data.message : `No account was found.`
    );
  }
}

export class MemberHasAcceptedInviteError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.MEMBER_HAS_ACCEPTED_INVITE,
      err
        ? err.response.data.message
        : 'Cannot delete an invite for an already accepted Member Invite.'
    );
  }
}

export class UserNotAdminError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.USER_NOT_ADMIN,
      err
        ? err.response.data.message
        : 'Fetching accounts is an admin privilege.'
    );
  }
}

export class BuyerAccountHasAudiencesError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.ACCOUNT_HAS_ACTIVE_AUDIENCE,
      err
        ? err.response.data.message
        : 'Buyer account has active audiences that should be removed first'
    );
  }
}

export class DeleteNotAllowedError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.DELETE_NOT_ALLOWED,
      err
        ? err.response.data.message
        : 'Delete operation is not allowed in current environment.'
    );
  }
}

export class BuyerPlatformSeatHasDealsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_HAS_DEALS,
      err
        ? `${err.response.data.message} Please contact support if you like to remove it.`
        : 'Cannot delete DSP seat as it has associated deals. Please contact support if you like to remove it.'
    );
  }
}

export class DSPSeatNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_HAS_DEALS,
      err ? err.response.data.message : 'DSP seat not found.'
    );
  }
}

export class BuyerPlatformSeatAccountMismatchError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_ACCOUNT_MISMATCH,
      err
        ? err.response.data.message
        : 'DSP Seat is not associated with the current account'
    );
  }
}

export class SSPSeatNotFoundError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.SSP_SEAT_NOT_FOUND,
      err ? err.response.data.message : 'SSP seat not found.'
    );
  }
}

export class SSPSeatAccountMismatchError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.SSP_SEAT_ACCOUNT_MISMATCH,
      err
        ? err.response.data.message
        : 'SSP Seat is not associated with the current account.'
    );
  }
}

export class UserAlreadyExistsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.USER_ALREADY_HAS_PROFILE,
      err ? err.response.data.message : 'Trying to add already existing member.'
    );
  }
}

export class BuyerPlatformSeatAlreadyExistsError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.BUYER_PLATFORM_SEAT_ALREADY_EXIST_ERROR,
      err ? err.response.data.message : 'Buyer platform seat already exists.'
    );
  }
}

export class TaxonomyDealsCreationError extends ClientError {
  constructor(err?: BackendError) {
    super(
      AccountsErrorCodes.TAXONOMY_DEALS_CREATION_ERROR,
      err
        ? err.response.data.message
        : 'Failed to create taxonomy deals for buyer DSP seats.'
    );
  }
}
