import { AccountInfo, BuyerPlatform, BuyerPlatformSeat } from 'shared-types';
import { computed, ComputedRef } from 'vue';
import { useAccountStore } from '@/store/modules/account/account-store';

export const useCurrentAccountInfo = (): {
  isAccountLoaded: ComputedRef<boolean>;
  account: ComputedRef<AccountInfo | undefined>;
  isAccountApproved: ComputedRef<boolean | undefined>;
  accountId: ComputedRef<string | undefined>;
  accountWebsite: ComputedRef<string | undefined>;
  accountName: ComputedRef<string | undefined>;
  buyerPlatformSeats: ComputedRef<BuyerPlatformSeat[] | null>;
  isAdmin: ComputedRef<boolean | undefined>;
  getBuyerDspByCode: (
    code: string,
    platform: BuyerPlatform
  ) => BuyerPlatformSeat;
} => {
  const accountStore = useAccountStore();

  const isAccountLoaded = computed(
    () => accountStore.isAccountLoaded && accountStore.isUserLoaded
  );

  const getComputedIfLoaded = <T>(
    toCompute: () => T
  ): ComputedRef<T | undefined> =>
    computed(() => {
      if (!isAccountLoaded.value) {
        return undefined;
      }
      return toCompute();
    });

  const account = getComputedIfLoaded(() => accountStore.account);

  // TODO: Check usages and remove this and store methods
  const getBuyerDspByCode = (
    code: string,
    platform: BuyerPlatform
  ): BuyerPlatformSeat => {
    return accountStore.getBuyerDspById(code, platform);
  };

  const accountId = getComputedIfLoaded(() => accountStore.selectedAccountId);

  const isAccountApproved = computed(() => account.value?.isApproved);
  const accountWebsite = computed(() => account.value?.website);
  const accountName = computed(() => account.value?.name);

  const buyerPlatformSeats = computed(
    () => account.value?.buyerPlatformSeats ?? null
  );

  const isAdmin = getComputedIfLoaded(() =>
    accountStore.user ? accountStore.user.isAdmin : false
  );

  return {
    isAccountLoaded,
    account,
    getBuyerDspByCode,
    isAccountApproved,
    accountId,
    accountWebsite,
    accountName,
    buyerPlatformSeats,
    isAdmin,
  };
};
