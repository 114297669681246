import {
  apiClient,
  isAxiosRequestAbortedError,
  isAxiosTooManyRequestsError,
} from '@/store/api/client';
import { RequestAborted } from '@/store/errors/ClientError';
import {
  AnalyticsReportDefinitionPayload,
  AudienceConversionRateByDealResponse,
  AudienceHealthAvgBidsByDealReport,
  AudienceHealthBidRequestsByDealReport,
  AudienceHealthBlockedDomainsReport,
  AudienceHealthCardsReport,
  AudienceHealthDealsReport,
  AudienceHealthSpendByDealReport,
  AudiencePlanResponse,
  BrowserReport,
  ChromeTopicReport,
  DeviceReport,
  DomainReport,
  GeoReport,
  GraphReport,
  HourReport,
  OverviewBuyerCardsReport,
  OverviewFilterOptions,
  OverviewFilterValues,
  OverviewReportByBrandItem,
  OverviewReportByDayItem,
  OverviewReportByPublisherItem,
  OverviewReportBySellerItem,
  OverviewReportType,
  RejectionReasonsReport,
  TrendReport,
} from 'shared-types';
import * as AnalyticsErrors from '@/store/errors/analytics/errors';
import { analyticsErrorGuard } from '@/store/errors/analytics/guards';

export const getOverviewFilterValues = async (
  accountId: string
): Promise<OverviewFilterValues> => {
  const { data } = await apiClient.get<OverviewFilterValues>({
    url: '/reports/overview/filter-values',
    options: {
      params: {
        accountId,
      },
    },
    retryOnFail: true,
  });
  return data;
};

export type OverviewReportByBuyerItem = {
  buyerName: string;
  spend: number;
};

export const getOverviewReport = async ({
  accountId,
  reportType,
  filterOptions,
}: {
  accountId: string;
  reportType: OverviewReportType;
  filterOptions?: OverviewFilterOptions;
}): Promise<
  | OverviewReportByDayItem[]
  | OverviewReportByPublisherItem[]
  | OverviewReportBySellerItem[]
  | OverviewReportByBrandItem[]
  | OverviewReportByBuyerItem[]
> => {
  let params: {
    [key: string]: string | number | undefined;
  } = {
    accountId,
    reportType,
  };

  if (filterOptions) {
    params = {
      ...params,
      ...filterOptions,
    };
  }

  const { data } = await apiClient.get<
    | OverviewReportByDayItem[]
    | OverviewReportByPublisherItem[]
    | OverviewReportBySellerItem[]
    | OverviewReportByBrandItem[]
  >({
    url: '/reports/overview',
    options: {
      params,
    },
    retryOnFail: true,
  });
  return data;
};

export const getOverviewCardsReport = async ({
  accountId,
  filterOptions,
}: {
  accountId: string;
  filterOptions?: OverviewFilterOptions;
}): Promise<OverviewBuyerCardsReport> => {
  let params = {
    accountId,
    reportType: 'CARDS',
  };

  if (filterOptions) {
    params = {
      ...params,
      ...filterOptions,
    };
  }

  const { data } = await apiClient.get<OverviewBuyerCardsReport>({
    url: '/reports/overview',
    options: {
      params,
    },
    retryOnFail: true,
  });
  return data;
};

export type GetAudienceHealthReportInput = {
  accountId: string;
  audienceId: string;
};

export const getAudienceHealthDealsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthDealsReport> => {
  const params = {
    ...input,
    reportType: 'DEALS',
  };

  const { data } = await apiClient.get<AudienceHealthDealsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthCardsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthCardsReport> => {
  const params = {
    ...input,
    reportType: 'CARDS',
  };

  const { data } = await apiClient.get<AudienceHealthCardsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthSpendByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthSpendByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'SPEND_BY_DEAL',
  };
  const { data } = await apiClient.get<AudienceHealthSpendByDealReport[]>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthBidRequestsByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthBidRequestsByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'BID_REQUESTS_BY_DEAL',
  };
  const { data } = await apiClient.get<AudienceHealthBidRequestsByDealReport[]>(
    {
      url: '/reports/audience-health',
      options: {
        params,
      },
      retryOnFail: true,
    }
  );

  return data;
};

export const getAudienceHealthAvgBidsByDealReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthAvgBidsByDealReport[]> => {
  const params = {
    ...input,
    reportType: 'AVG_BID_BY_DEAL',
  };

  const { data } = await apiClient.get<AudienceHealthAvgBidsByDealReport[]>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export type GetAudienceConversionRateReportInput = {
  accountId: string;
  audienceId: string;
};

export const getAudienceConversionRateByDealReport = async (
  input: GetAudienceConversionRateReportInput
): Promise<AudienceConversionRateByDealResponse> => {
  const { data } = await apiClient.get<AudienceConversionRateByDealResponse>({
    url: '/reports/feed-audience-conversion-rate-by-deal',
    options: {
      params: input,
    },
    retryOnFail: true,
  });

  return data;
};

export const getAudienceHealthBlockedDomainsReport = async (
  input: GetAudienceHealthReportInput
): Promise<AudienceHealthBlockedDomainsReport> => {
  const params = {
    ...input,
    reportType: 'BLOCKED_DOMAINS',
  };

  const { data } = await apiClient.get<AudienceHealthBlockedDomainsReport>({
    url: '/reports/audience-health',
    options: {
      params,
    },
    retryOnFail: true,
  });

  return data;
};

export const getRejectionReasonsReport = async (
  dealId: number
): Promise<RejectionReasonsReport> => {
  try {
    const { data } = await apiClient.get<RejectionReasonsReport>({
      url: '/reports/rejection-reasons',
      options: {
        params: { dealId },
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (analyticsErrorGuard.isNoRejectionReasonForDealIdError(err)) {
      throw new AnalyticsErrors.NoRejectionReasonsForDealId(err);
    }
    throw err;
  }
};

export const getTrendReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  intervalDays: number;
  abortSignal?: AbortSignal;
}): Promise<TrendReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<TrendReport>({
      url: '/reports/trends',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getDomainReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  abortSignal?: AbortSignal;
}): Promise<DomainReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<DomainReport>({
      url: '/reports/domain',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getDeviceReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  abortSignal?: AbortSignal;
}): Promise<DeviceReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<DeviceReport>({
      url: '/reports/device',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getBrowserReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  abortSignal?: AbortSignal;
}): Promise<BrowserReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<BrowserReport>({
      url: '/reports/browser',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getGeoReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  countryCode: string;
  abortSignal?: AbortSignal;
}): Promise<GeoReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<GeoReport>({
      url: '/reports/geo',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getGraphReport = async (input: {
  tags: string[];
  abortSignal?: AbortSignal;
}): Promise<GraphReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<GraphReport>({
      url: '/reports/graph',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getHourReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  abortSignal?: AbortSignal;
}): Promise<HourReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<HourReport>({
      url: '/reports/hour',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export const getChromeTopicReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  abortSignal?: AbortSignal;
}): Promise<ChromeTopicReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<ChromeTopicReport>({
      url: '/reports/chrome-topic',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export type YoutubeChannelsReport = {
  [key: string]: string | number | null;
  channelId: string;
  channelName: string;
  channelCustomUrl: string;
  channelThumbnail: string;
  channelCategory: string;
}[];

export const getYoutubeChannelsReport = async (input: {
  audienceDefinition: AnalyticsReportDefinitionPayload;
  isDownload?: boolean;
  abortSignal?: AbortSignal;
}): Promise<YoutubeChannelsReport> => {
  try {
    const { abortSignal, ...params } = input;
    const { data } = await apiClient.get<YoutubeChannelsReport>({
      url: '/reports/youtube-channels',
      options: {
        params,
        signal: abortSignal,
      },
      retryOnFail: true,
    });

    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export enum AudiencePlanReport {
  TRENDS = 'TRENDS',
  DOMAIN = 'DOMAIN',
  CHROME_TOPICS = 'CHROME_TOPICS',
  BROWSER = 'BROWSER',
  HOUR = 'HOUR',
  DEVICE = 'DEVICE',
  GEO = 'GEO',
  GRAPH = 'GRAPH',
  WEBSITES = 'WEBSITES',
  SUGGESTED_AUDIENCES = 'SUGGESTED_AUDIENCES',
  YOUTUBE_CHANNELS = 'YOUTUBE_CHANNELS',
}

export const getAudiencePlan = async (input: {
  hash: string;
  countryCode?: string;
  reports?: AudiencePlanReport[];
}): Promise<AudiencePlanResponse | null> => {
  try {
    const { data } = await apiClient.get<AudiencePlanResponse | null>({
      url: `/reports/audience-plan?hash=${input.hash}`,
      options: {
        allowUnauthenticated: true,
        params: {
          reports:
            input.reports === undefined
              ? Object.keys(AudiencePlanReport)
              : input.reports,
          countryCode: input.countryCode,
        },
      },
    });
    return data;
  } catch (err) {
    if (isAxiosTooManyRequestsError(err)) {
      throw new AnalyticsErrors.AudiencePlanRateLimitError();
    } else if (analyticsErrorGuard.isFailedToDecodeHashError(err)) {
      return null;
    }
    throw err;
  }
};

export type DimensionDataItem = {
  value: string;
  metric: string | number;
  item: string;
};

export type DimensionItem = {
  dimension: string;
  friendlyName: string;
  data: DimensionDataItem[];
};

export type MetricDataItem = {
  hour: string;
  [key: string]: string | number;
};

export enum MetricValueType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  COUNT = 'count',
}

export type MetricMetadata = {
  name: string;
  friendlyName: string;
  totalValue: number;
  benchmarkValue: number;
  type: MetricValueType;
};

export type MetricItem = {
  metadata: MetricMetadata[];
  data: MetricDataItem[];
};

export interface PerformanceReportResponse {
  table: string;
  dimensions: DimensionItem[];
  metrics: MetricItem;
}

export type PerformanceReportFilter = {
  dimensionFilters?: DimensionFilter[];
  timeFilter?: { days: number };
  table?: string;
  metric?: string;
};

export type DimensionFilter = { dimension: string } & (
  | { in: string[] | number[]; notIn?: never }
  | { in?: never; notIn: string[] | number[] }
);

export type PerformanceTablesResponse = {
  name: string;
  friendlyName: string;
};

export const getPerformanceReportTables = async (accountId: string) => {
  const { data } = await apiClient.get<{
    result: PerformanceTablesResponse[];
  }>({
    url: '/reports/performance-tables',
    options: {
      params: {
        accountId,
      },
    },
  });

  return data.result;
};

export const getPerformanceReport = async ({
  accountId,
  audienceId,
  filter,
  abortSignal,
}: {
  accountId: string;
  audienceId: string;
  filter?: PerformanceReportFilter;
  abortSignal?: AbortSignal;
}) => {
  try {
    const payload = {
      audienceId,
      accountId,
      ...filter,
    };
    const { data } = await apiClient.post<{
      result: PerformanceReportResponse;
    }>({
      url: '/reports/performance',
      data: payload,
      options: {
        signal: abortSignal,
      },
    });

    return data.result;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export enum AttributionPixelGroupDownloadReportType {
  DEBUG = 'DEBUG',
  NORMAL = 'NORMAL',
}

export type AttributionPixelGroupDownloadReportRequest = {
  attributionPixelGroupId: number;
  accountId: string;
};

export type AttributionPixelGroupDownloadReportResponse = {
  attributionPixelGroupId: number;
  hour: string;
  conversions: number;
  debugConversions: number;
  impressions: number;
  dsp: string;
};

export const getAttributionPixelGroupDownloadReport = async (
  input: AttributionPixelGroupDownloadReportRequest
): Promise<AttributionPixelGroupDownloadReportResponse[]> => {
  try {
    const { data } = await apiClient.get<
      AttributionPixelGroupDownloadReportResponse[]
    >({
      url: '/reports/attribution-pixel-group-download',
      options: {
        params: {
          ...input,
        },
      },
    });

    return data;
  } catch (err) {
    if (analyticsErrorGuard.isInvalidReportTypeError(err)) {
      throw new AnalyticsErrors.InvalidReportTypeError(err);
    }
    throw err;
  }
};

export type PublishersPageViewsByDayItem = {
  day: string;
  cnt: number;
};

export type PublishersPageViewsByCountryItem = {
  country: string;
  cnt: number;
};

export type PublishersPageViews = {
  [id: string]: {
    byDay: PublishersPageViewsByDayItem[];
    byCountry: PublishersPageViewsByCountryItem[];
  };
};

export enum PublisherPageViewsReportType {
  BY_DAY = 'byDay',
  BY_COUNTRY = 'byCountry',
}

export async function getPublisherPageViewsReports(): Promise<PublishersPageViews> {
  const { data } = await apiClient.get({
    url: '/reports/publisher-page-views',
    retryOnFail: true,
    options: {
      params: {
        reportTypes: [
          PublisherPageViewsReportType.BY_DAY,
          PublisherPageViewsReportType.BY_COUNTRY,
        ],
      },
    },
  });

  return data;
}
