<template>
  <div class="base-notification">
    <transition name="fade">
      <loading-feedback-chip v-if="isLoading">
        Loading...
      </loading-feedback-chip>
    </transition>
    <transition-group name="fade" tag="div">
      <template
        v-for="(success, index) in successes"
        :key="`${success.id}-success`"
      >
        <success-feedback-chip
          :style="`margin-bottom: ${(Number(isLoading) + index) * 60}px`"
        >
          {{ success.message ?? 'Success!' }}
        </success-feedback-chip>
      </template>
    </transition-group>
    <transition-group name="fade" tag="div">
      <template v-for="(error, index) in errors" :key="`${error.id}-error`">
        <error-feedback-chip
          :style="`margin-bottom: ${
            (Number(isLoading) + index + successes.length) * 60
          }px`"
        >
          {{ error.message ?? 'Unexpected error. Please, try again later.' }}
        </error-feedback-chip>
      </template>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import LoadingFeedbackChip from '@/components/UI/Chips/LoadingFeedbackChip.vue';
import SuccessFeedbackChip from '@/components/UI/Chips/SuccessFeedbackChip.vue';
import ErrorFeedbackChip from '@/components/UI/Chips/ErrorFeedbackChip.vue';
import {
  Notification,
  NotificationType,
  useUserInterfaceStore,
} from '@/store/modules/user-interface';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'BaseNotification',
  components: {
    LoadingFeedbackChip,
    SuccessFeedbackChip,
    ErrorFeedbackChip,
  },
  setup() {
    const userInterfaceStore = useUserInterfaceStore();
    const { notifications, isLoading } = storeToRefs(userInterfaceStore);

    const successes = computed(() =>
      notifications.value.filter(
        (item: Notification) => item.type === NotificationType.SUCCESS
      )
    );
    const errors = computed(() =>
      notifications.value.filter(
        (item: Notification) => item.type === NotificationType.ERROR
      )
    );

    return {
      isLoading,
      successes,
      errors,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define base-notification

@import '@/styles/animations';

.base-notification {
  * {
    z-index: var(--z-notification);
  }
}
</style>
