import { OktaAuth } from '@okta/okta-auth-js';

const oktaConfig = {
  clientId: GLOBALCONFIG.okta.clientId,
  issuer: GLOBALCONFIG.okta.issuer,
  baseUrl: GLOBALCONFIG.okta.issuer.split('/oauth2')[0],
  redirectUri: `${GLOBALCONFIG.appFrontendURL}/auth-redirect`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  useInteractionCodeFlow: true,
  idps: [
    {
      type: 'GOOGLE',
      id: String(GLOBALCONFIG.okta.googleIdentityProviderId),
    },
  ],
};

const clientParams = {
  clientId: oktaConfig.clientId,
  issuer: oktaConfig.issuer,
  redirectUri: oktaConfig.redirectUri,
  scopes: oktaConfig.scopes,
  pkce: oktaConfig.pkce,
  useInteractionCodeFlow: oktaConfig.useInteractionCodeFlow,
};
const oktaAuthClient = new OktaAuth(clientParams);

export { oktaAuthClient, oktaConfig };
